//用户权限
export const MENUTYPE = {
    ADMIN: 15, //管理员
    FRANCHISER: 20, //经销商
    LANDLORD: 21, //房东
    TENANT: 22 //房客
}

//登录设备
export const VENDINGTYPE = {
    PC: 1,
    // ANDROID: 9,
    // IOS: 8
}

//接入设备类型
export const DEVICETYPE = {
    ELE:0,
    WATER:1,
    HOT_WATER:3
}

//支付方式
export const paytypeList = [
    { label: 'lang.all', value: null }, //全部
    { label: 'lang.alipay', value: 1 }, //支付宝
    { label: 'lang.bankcard', value: 2 }, //银行卡
    { label: 'lang.wechat', value: 3 }, //微信
    { label: 'lang.alipaysmall', value: 10 }, //支付宝小程序
    { label: 'lang.wechatsmall', value: 11 }, //微信小程序
    { label: 'lang.vrpay', value: 128 }, //虚拟支付
]