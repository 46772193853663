export default {
    namespaced: true,
    state: {
        activePage: 1,//活跃页面：1主页面 2详情页面
        rowData: {},//行数据
        ccid: null,
        cancelorderid: null,
    },
    getters: {
        activePage: state => { return state.activePage; },
        rowData: state => { return state.rowData; },
        ccid: state => { return state.ccid; },
        cancelorderid: state => { return state.cancelorderid; },
    },
    mutations: {
        activePage(state, val) { state.activePage = val; },
        rowData(state, val) { state.rowData = val; },
        ccid(state, val) { state.ccid = val; },
        cancelorderid(state, val) { state.cancelorderid = val; },
    }
}