import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './app/i18n/global.js'
import './plugins/element.js'
import './services/service.js'
import * as filters from './global/filters.js'
import * as glb from './global/glbFunction.js'
import IconSvg from './app/components/icon-component/icon-component.vue'
// 用于关闭[Violation]警告
import 'default-passive-events'
import commonApi from './store/commonApi'

// 自定义主题
// import '@/theme/index.css';


// 导入全局样式
import './assets/css/global.css'
import './assets/css/Layout.css'
import './assets/css/fontclass.css'
// 导入iconfont symbol
import './assets/css/iconfont.js'

Vue.component('icon-svg', IconSvg)

//导出excel
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

// 注册全局过滤器
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

Vue.prototype.commonApi = commonApi;
Vue.prototype.$bus = new Vue() // 兄弟组件传值
Vue.config.productionTip = false

document.title = '智电生态'

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')