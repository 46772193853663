import Vue from 'vue'
import axios from 'axios'
import store from "../store"
// 配置请求根路径
// axios.defaults.baseURL = 
//                   'http://10.10.15.49:8888/api/private/v1/';
// 'http://192.168.1.102:8888/api/private/v1/';

//添加一个请求拦截器
axios.interceptors.request.use(function (config) {
    //为请求头对象,添加token验证的Authorization字段
    config.headers.HTTP_ACCESS_TOKEN_TMP = window.sessionStorage.getItem('token');
    config.headers.HTTP_ACCESS_TOKEN = window.sessionStorage.getItem('token');

    //我联服务不能加这些header
    if (!config.url.includes("/smartaps/")) {
        config.headers.service = '1';
        config.headers.os = 'zd_pc_admin';
        config.headers.app_version = '1.1.0';
    }
    //我联服务需要我联登录的token
    else config.headers.HTTP_ACCESS_TOKEN = store.getters["account/wlToken"];
    return config;
}, function (err) {
    //处理响应的错误信息
})

// 添加一个响应拦截器
axios.interceptors.response.use(function (res) {
    // 在这里对返回的数据进行处理
    return res.data;
}, function (err) {
    // console.log(err.response);
    throw err.response;
    //处理响应的错误信息
});

Vue.prototype.$http = axios;