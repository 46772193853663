import { post, get, downPost } from "../services/http";
import environment from '../environment';
const {
    HOSTHOME: hosthome,
    URL_SERVICE_NOTICE,
    URL_SERVICE,
    URL_USERSYSTEM,
    URL_SERVICE_ORDER,
    URL_SERVICE_DEVICE,
    URL_SERVICE_UNIFIED,
    URL_SERVICE_DEVICEHISTORY,
    URL_SERVICE_SPRINGSMARTAPS,
    URL_SERVICE_ROOMSERVICE
} = environment;
const actions = {
    addKeepAlivePage({ commit }, name) {
        commit('ADD_KEEP_ALVE', name)
    },
    delKeepAlivePage({ commit }, name) {
        commit('DEL_KEEP_ALVE', name)
    },

    /**
     * 房东和管理员共用接口
     */

    /**
     * 
     * @param {*} URL_USERSYSTEM usersystem
     * @param {*} params 
     * @returns 
     */
    //验证账号是否已存在
    Login_CheckPhoneOrAccountOrEmail({ dispatch, commit, state }, params) {
        return post(URL_USERSYSTEM + '/register/checkPhoneOrAccountOrEmail', params)
    },
    //发送短信验证码
    Login_SendSMSVCode({ dispatch, commit, state }, params) {
        return post(URL_USERSYSTEM + '/register/sendSMSVCode', params)
    },
    //发送短信验证码
    Login_VerifyCode({ dispatch, commit, state }, params) {
        return post(URL_USERSYSTEM + '/register/verifyCode', params)
    },
    //注册
    Login_Register({ dispatch, commit, state }, params) {
        return post(URL_USERSYSTEM + '/register/register', params)
    },
    //重新设置密码
    Login_ResetPassword({ dispatch, commit, state }, params) {
        return post(URL_USERSYSTEM + '/register/resetPassword', params)
    },
}


export default actions