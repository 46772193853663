export default {
    namespaced: true,
    state: {
        orderid: null,
    },
    getters: {
        orderid: state => { return state.orderid; },
    },
    mutations: {
        orderid(state, val) { state.orderid = val; },
    }
}