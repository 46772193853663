import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import en from './lang/en.js'
import zh from './lang/zh.js'



Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'zh-CN', //语言标识,通过切换locale的值来实心语言切换
    messages: {
        'zh-CN': zh, //中文语言包
        'en-US': en //英文语言包
    },
    silentTranslationWarn: true, //去掉console.warning提示
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n