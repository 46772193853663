import { routes as pages } from '../app/pages'
const Login = () =>
    import( /* webpackChunkName: "auth" */ '@/app/auth/login/Login.vue')
const newLogin = () =>
    import( /* webpackChunkName: "auth" */ '@/app/auth/login/newLogin.vue')
const ForgetPassword = () =>
    import( /* webpackChunkName: "auth" */ '@/app/auth/login/forget-password.vue')
const Registered = () =>
    import( /* webpackChunkName: "auth" */ '@/app/auth/login/registered.vue')
const Home = () =>
    import( /* webpackChunkName: "auth" */ '../app/auth/home/home.vue')

export const routes = [

    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: newLogin,
    },
    {
        path: '/registered',
        name: 'Registered',
        component: Registered,
    },
    {
        path: '/forgetPassword',
        name: 'ForgetPassword',
        component: ForgetPassword,
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        redirect: 'goodsmanage',
        children: [
            ...pages
        ]
    },
]