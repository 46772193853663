export default {
    namespaced: true,
    state: {
        /**运费管理地址 */
        freightAddress: {
            time: "",
            value: ""
        },
        /**经销商管理和账号管理中的基本信息 */
        baseInfoAddress: {
            time: "",
            value: ""
        },
    },
    getters: {
        /**运费管理地址 */
        freight: state => () => {
            if (state.freightAddress.time == "") return null;
            if (timeInterval(state.freightAddress.time, getYMDHMS()) > cacheTime) return null;
            return state.freightAddress.value;
        },
        baseInfo: state => () => {
            if (state.baseInfoAddress.time == "") return null;
            if (timeInterval(state.baseInfoAddress.time, getYMDHMS()) > cacheTime) return null;
            return state.baseInfoAddress.value;
        },
    },

    mutations: {
        freight(state, val) {
            state.freightAddress.value = val;
            state.freightAddress.time = getYMDHMS();
        },
        baseInfo(state, val) {
            state.baseInfoAddress.value = val;
            state.baseInfoAddress.time = getYMDHMS();
        },
    }
}
const cacheTime = 3600;
function timeInterval(time1, time2) {
    return Math.floor(Math.abs((new Date(time1) - new Date(time2)) / 1000));
}
/**获得年月日时分秒 */
function getYMDHMS() {
    let dateTime;
    let date = new Date();
    let yy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    let hh = date.getHours();
    let mf = date.getMinutes();
    let ss = date.getSeconds();
    mm = mm < 10 ? "0" + mm : mm;
    dd = dd < 10 ? "0" + dd : dd;
    hh = hh < 10 ? "0" + hh : hh;
    mf = mf < 10 ? "0" + mf : mf;
    ss = ss < 10 ? "0" + ss : ss;
    dateTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
    return dateTime;
}