import enLocale from 'element-ui/lib/locale/lang/en'
import deepmerge from 'deepmerge'
let lang = {
    gotoabout: 'go to About',
    welcome_zd: "Welcome to zhidian ecology!!",
    input_account: "Please enter your account number.",
    tips_pictureformat: "The uploaded avatar image can only be in JPG JPEG GIF BMP format!",
    tips_picturesize: "The size of uploaded avatar image cannot exceed 5MB!",
    tips_picturewidth: "The picture size cannot be less than 500 x 500!",
    input_password: "Please enter your password",
    agree_rule: "By registering/logging in, you agree",
    article_zd: "《智电生态隐私规则》",
    login: "Login",
    installposition: "Installation position",
    addtime: "add time",
    version: "The version number",
    register: "Register",
    forgetpassword: "forget password",
    limit_password: "The length should be between 6 and 15 characters",
    login_success: "Login successful",
    hour: "hour",
    day: "day",
    datatime: "The data of time",
    month: "month",
    today: "today",
    yesterday: "yesterday",
    beforeweek: "beforeweek",
    year: "year",
    trafficfree: "Traffic Free Cycle",
    login_fail: "Login failed",
    historicaldata: "The historical data",
    poweranalysis: "Power utilization analysis",
    wateranalysis: "Water utilization analysis",
    prepaidrecord: "Prepaid phone records",
    IMEI: "IMEI",
    SIM: "SIM",
    qualityperiod: "The quality assurance period",
    zdst: "ZDST",
    logout: 'Exit the login',
    eidtacountinfo: 'Modify account information',
    homepage: 'home page',
    feesdetail: 'collection details',
    usedetail: 'use details',
    starttime: 'Start time',
    endtime: 'End time',
    monthlyrentgreater50: "The monthly rent must be greater than or equal to 50 yuan",
    monthlyrentint: "Monthly rent can only be an integer",
    selecttime: 'Please select a date',
    selectregion: 'Please select a region',
    enterdetailaddress: 'Please enter the detailed address',
    enterhousename: 'Please enter the house name',
    enterroomnumber: 'Please enter the number of rooms',
    selecteleprice: 'Please select electricity price',
    selectcoldprice: 'Please select cold water price',
    selecthotprice: 'Please select hot water price',
    housename: "House name",
    roomname: "Room name",
    roomnumber: "Room Number",
    roomedit: "Room Edit",
    select_house: "Please choose a house",
    input_roomname: "Enter a room name",
    input_roomnumber: "Enter the number of rooms",
    query: "Query",
    export: "Export",
    num: "Number",
    energycost: "Energy cost",
    elefees: "Ele fees",
    coldwatercost: "Water cost",
    coldwaterfees: "Water fees",
    hotwatercost: "Hot water cost",
    hotwaterfees: "Hot water fees",
    pubelecost: "Pub ele cost",
    pubelefees: "Pub ele fees",
    pubwatercost: "Pub water cost",
    pubwaterfees: "Pub water fees",
    subtotal: "Subtotal",
    subtotal2: "Subtotal",
    pubweight: "Pub weight",
    pubcustomize: "Pub customize",
    choosetime: "choose time",
    forrent: "For rent",
    waterrent: "water rent",
    publiccirclefee: "Public circle fee",
    costdetails: "Cost details",
    expensedetailsheet: "Expense detail sheet",
    expensetype: "Expense type",
    startindication: "Start indication",
    endindication: "End indication",
    choosetimetwo: "choose time",
    time: "time",
    subtotal: "Subtotal",
    inputnameandphone: "Please enter your name and cell phone number",
    starttime1: "start time",
    endtime1: "end time",
    operatetime: "Operating time",
    yuan: "(Yuan)",
    yeargather: "This year's gathering",
    monthgather: "This month's gathering",
    all: "All",
    alipay: "Alipay",
    bankcard: "Bank card",
    wechat: "WeChat",
    alipaysmall: "Pay treasure small program",
    wechatsmall: "WeChat applet",
    vrpay: "Virtual paying",
    payway: "Payment methods",
    select_payway: "Please select payment method",
    showproject: "According to the content",
    rent: "The rent",
    utilities: "The electricity and water",
    other: "other",
    serialnumber: "The serial number",
    tenantname: "Tenant name",
    project: "project",
    paymentamount: "Payment amount",
    paymentmethods: "Payment methods",
    paymenttime: "Payment time",
    unknown: "unknown",
    unchoose: "unchoose",
    collectiondetails: "collection details",
    Amountpay: "Payment amount",
    total: "Total",
    logintimeout: "The login timeout!",
    requesterror: "Network request error!",
    devicemanage: "Equipment management",
    devicename: "Device name",
    assetno: "The asset no",
    state: "state",
    concentrator: "Ownership concentrator",
    position: "Subordinate position",
    refreshtime: "The refresh time",
    equipdetail: "Equipment details",
    toview: "To view",
    nextprice: "Price effective next month",
    more: "more",
    yuankwh: "yuan/kW·h",
    refresh: "refresh",
    totalpower_unit: "Total power consumption (kW·h)",
    totalpower: "Total power consumption",
    ismakesure: "Whether to confirm",
    eleprice_unit: "Electricity price (Yuan /kW·h)",
    waterprice_unit: "Water (m after/kW · h)",
    totalwater_unit: "Total water consumption(m³)",
    reminapower_unit: "Remaining power (kW·h)",
    reminapower: "Remaining power",
    eleprice_y: "Electricity price (Yuan /kW·h)",
    pullclosestate: "Pull closing state",
    current: "current",
    voltage: "voltage",
    cutoffwater: "Cut off water",
    waterprice: "Water price",
    nullData: "No data",
    distributor: "distributor",
    system: "system",
    equipment: "equipment",
    roomwallet: "Room Wallet",
    paymentofarrears: "Payment of arrears",
    equipmentrentalfee: "Equipment rental fee",
    mallgoods: "Mall goods",
    mallmember: "Mall member",
    ownerannualfee: "Owner's annual fee",
    flowfee: "Flow fee",
    daytwo: "day",
    monthtime: "Month / time",
    advance: "advance",
    tenantmanagement: "Tenant management",
    rentmanagement: "Rent management",
    tenantnametwo: "Tenant name",
    monthrenttwo: "Monthly rent",
    powerofftime: "Power off time",
    rentcollectioncycle: "Rent collection cycle",
    rentcollectionreminder: "Rent collection reminder",
    alarmbalance: "Alarm balance",
    yuanmonth: "yuan/month",
    delay: "delay",
    notselected: "Not selected",
    alipayappletscancodepayment: "Alipay applet scan code payment",
    wechatappletcodescanningpayment: "Wechat applet code scanning payment",
    paymenttimetwo: "Payment time",
    moneyyuan: "amount of money(yuan)",
    consumptionyuan: "consumption（yuan/kW.h）",
    managementexpenseyuan: "management expense（yuan）",
    costyuan: "cost（yuan）",
    modifywatertariff: "Modify water tariff",
    thresholdsetting: "Threshold setting",
    priceyuan: "price（yuan/kW.h）",
    factorpower: "power factor",
    emeter: "Electric meter",
    pubemeter: "Equally Shared electric meter",
    wmeter: "Water meter",
    pubwmeter: "Equally Shared water meter",
    coldwmeter: "Cold wmeter",
    hotwmeter: "Hot wmeter",
    concentrator: "concentrator",
    inputdevicename: "Please enter a device name or asset number",
    myhouse: "housing resource",
    businesscenter: "The business center",
    tenantmanage: "tenant management",
    concentrator: "concentrator",
    inputdevicename: "Please enter a device name or asset number",
    myhouse: "housing resource",
    housemanage: "housing manage",
    operate: "Operation",
    area: "Area",
    rooms: "Rooms",
    tenantcount: "Tenant count",
    metercount: "Meter count",
    watermetercount: "WMeter count",
    rented: "Rented",
    edithouse: "Edit",
    delhouse: "Delete",
    invoicemanagement: "Invoice Management",
    invoiceheader: "Invoice header",
    invoicestatus: "Invoice status",
    tobeinvoiced: "To be invoiced",
    invoicetime: "Invoice time",
    billingdetails: "Billing details",
    common: "common",
    strip: "strip",
    taxpayeridentificationnumber: "Taxpayer identification number",
    address: "address",
    phone: "phone",
    accountbankandaccount: "Account bank and account number",
    invoiced: "Invoiced",
    customernumber: "Customer number",
    invoiceamountyuan: "Total invoice amount (yuan)",
    invoicetype: "Invoice type",
    financialcenter: "Financial Center",
    useraccount: "User account",
    cellphonenumber: "cell-phone number",
    enteraccountorphone: "Please enter user account or mobile phone number",
    enterordernumber: "Please enter the order number",
    applicationtime: "Application time",
    startdate: "Start date",
    enddate: "End date",
    invoicenumber: "Invoice number",
    toinput: "please input",
    roominfo: "Room info",
    publicarea: "Public area",
    roomstatus: "Room status",
    haverent: "Already Rent",
    notrent: "Not Rent",
    waitforconfirmation: "Wait For Confirmation",
    tenant: "Tenant",
    turnedon: "Turned on",
    equipmentinformation: "Equipment information",
    rentmanagementfunction: "Rent management function",
    contactway: "Phone",
    dueTime: "Due Date",
    rentmoney: "Rent money",
    lastmoney: "Balance of utilities",
    meter: "Meter",
    watermeter: "WMeter",
    status: "Status",
    month: "month",
    editname: "Edit name",
    addtenant: "Add tenant",
    delroom: "Delete",
    adddevice: "Add device",
    coststatistics: "Cost statistics",
    paymentbill: "Payment bill",
    operaterecord: "Operate Record",
    rentalagreement: "Rental agreement",
    rentwithdrawal: "Rent withdrawal",
    setalarm: "Set alarm",
    balanceclear: "Balance clear",
    roommanagement: "Room Management",
    addroom: "Add room",
    offline: "offline",
    poweroutages: "Power outages",
    electricity: "electricity",
    equipmenttype: "Equipment type",
    accessdevices: "access devices",
    abnormaldevices: "abnormal devices",
    apmetername: "AP Meter name",
    rentwithdrawalsuccess: "rent with drawal success",
    remaindaysofrent: "remain days of rent",
    permissionmanage: "permission manage",
    systemsettings: "system settings",
    tatol: "tatol",
    stopRent: "stopRent",
    torent: "rent",
    amountofrentrefundable: "amount of rent refundable",
    apmetername: "AP Meter name",
    accumulatedtopup: "accumulated top up",
    cumulativeequallyshared: "cumulative equally shared",
    cumulativewater: "cumulative water",
    totalelectricity: "total electricity",
    rentstartandend: "Rent starts and ends",
    idnumber: "ID number",
    checkdetail: "check detail",
    detail1: "detail",
    checkintime: "Check-in time",
    currentmoney: "The current balance",
    phone: "phone",
    name1: "name",
    name: "name",
    apmeterid: "AP Meter NO",
    totalusepw: "Total cost",
    monthusepw: "Month cost",
    unknownpage: "Unknown page",
    pricepw: "Price",
    devicetype: "Device Type",
    devicestate: "Device state",
    freshtime: "Refresh time",
    yuan1: "Yuan",
    deposit: "deposit",
    edit2: "edit",
    waterpricemanagement: "Water price management",
    offline: "Offline",
    tochoose: "Please select",
    online: "Online",
    closedswitch: "Switch On",
    pullswitch: "Switch Off",
    setapportion: "Set apportion",
    untyingdevice: "Untying device",
    editprice: "Edit price",
    devicereplace: "Device replace",
    eleprice: "Ele price",
    waterprice: "Water price",
    hotwaterprice: "Hot water price",
    newpricetip: "Currently displayed as effective amount, effective amount next month",
    addhouse: "Add house",
    roleName: "role name",
    roleDescribe: "role describe",
    newRole: "new Role",
    addhouse: "Add house",
    save: "save",
    allChoose: "all Choose",
    savesuccess: "save success",
    rolemanage: "role manage",
    employeesname: "employees name",
    employeesphone: "employees phone",
    employeesrole: "employees role",
    savefail: "save fail",
    describe: "describe",
    notice: "Notice",
    alarminformation: "Alarm information",
    accountmessage: "Account message",
    roommessage: "Room message",
    listingnews: "Listing news",
    problemfeedback: "Problem feedback",
    nomessage: "No message",
    accountsettings: "Account Settings",
    modifyerror: "The name cannot contain special characters",
    delete: "delete",
    role: "role",
    complete: "complete",
    messagerecord: "Message record",
    devicerefresh: "Device refresh",
    deviceunbinding: "Device unbinding",
    clearingandstealingelectricity: "Clearing and stealing electricity",
    restorefactorysettings: "Restore factory settings",
    enteremployee: "Please enter the employee name/phone number",
    addemployees: "add employees",
    makesuredelthisrole: "Are you sure you want to delete the role?",
    duplicaterolename: "Duplicate role name!",
    addroles: "add roles",
    delstaff: "delete staff",
    delroles: "delete roles",
    alluser: "all user",
    rolepermissions: "role permissions",
    employeeroleassignment: "employee role assignment",
    operationlog: "operation log",
    cancel: "cancel",
    determine: "determine",
    determine1: "determine",
    editsuccess: "Modified successfully",
    delconcentrator: "Delete concentrator",
    delsuccess: "Successfully deleted",
    tips: "tips",
    isdelconcentrator: "Do you want to delete the concentrator",
    searchareatip: "Please enter a region name",
    isdelroom: "Whether to delete the room",
    inarea: "In the area",
    detailedaddress: "Detailed address",
    number: "number",
    onethousanditemscanbeexported: "Up to 1000 items can be exported",
    askm: "",
    delsuccess: "Successfully deleted",
    delfail: "delete fail",
    tips: "tips",
    isdelconcentrator: "Do you want to delete the concentrator",
    makesuredel: "Do you want to delete",
    searchareatip: "Please enter a region name",
    isdelroom: "Whether to delete the room",
    newpassword: "new password",
    passwordtips: "The new password must contain at least one uppercase letter, one lowercase letter, and one digit.",
    comfirmidentity: "To confirm your identity, we still need to verify your secure cell phone",
    getsms: "Obtain the SMS verification code",
    usesecurephone: "Please use a secure cell phone",
    sendsms: "Click the Send SMS button and a text message will be sent to the mobile phone",
    comfirmidentity1: "To confirm your identity, we still need to verify your secure cell email",
    getsms1: "Obtain the SMS verification code",
    usesecurephone1: "Please use a secure cell email",
    add: "add",
    emailincorrect: "email incorrect",
    invalidformat: "invalid format",
    email: 'email',
    smsverificationfail: "The verification code failed. Procedure",
    smscode: "code",
    smscodefail: "smscode fail",
    phoneregistered: "The phone number has been registered",
    smscodesuccess: "The verification code is sent successfully. Procedure",
    sendmessage: "send message",
    resend: "To resend",
    sendsms1: "Click the Send SMS button and a text message will be sent to the email",
    fail: "fail",
    passwordsinconsistent: "The two passwords are inconsistent!",
    reset: "reset",
    submit: "submit",
    comfirmpassword: "comfirm password",
    originalpassword: "original password",
    Changethepassword: "Change the password",
    personalcenter: "personal center",
    isdel: 'Whether to delete ',
    inarea: "In the area",
    rentpaidinfull: "Rent has been paid in full",
    detailedaddress: "Detailed address",
    rentmanagementtips: "The current room rent management function is not enabled. Do you want to enable it?",
    rentmanagementtips1: "If open, please select whether the current rent has been paid.",
    rentmanagementtips2: "If the rent management function is opened without payment, the room will be automatically powered off",
    number: "number",
    rentmanagementconfirmation: "rent management confirmation",
    remark: "remark",
    rentlease: "The rent lease",
    numberInput: "The number of rooms ranges from 1 to 100",
    addsuccess: "Add a success",
    room: "room",
    isdelhousing: "Whether to delete the listing or not",
    int: "The integer",
    unbindsharedtip: "After untying, the following room share weights will be cleared to zero",
    balancetip: "Do you want to clear the balance of the utility bill?",
    temonlybe50to100: "The ambient temperature value shall be an integer of 50 ~ 100",
    temonlybeinteger: "The temperature value can only be an integer",
    notbeempty: "not be empty",
    phoneincorrect: "The format of the phone number is incorrect",
    read: "read",
    isclearstealele: "is clear steal ele",
    powerthreshold: "power threshold",
    linetemperature: "line temperature",
    ambienttemperature: "ambient temperature",
    set: "set",
    clearornot: "Clear or not?",
    edit1: 'edit1',
    priceedit: "price edit",
    unbind: "unbind",
    sharedweight: "shared weight",
    determineunbind: "determine unbind",
    int: "The integer",
    unbindsharedequiment: "unbind shared equiment",
    editelename: "edit ele name",
    equipmentlist: "The equipment list",
    Aphasecurrent: "A phase current",
    Bphasecurrent: "B phase current",
    Cphasecurrent: "C phase current",
    Aphasevoltage: "A phase voltage",
    Bphasevoltage: "B phase voltage",
    Cphasevoltage: "C phase voltage",
    powerfactor: "The power factor",
    powerw: "power(W)",
    startingtime: "Starting time",
    endingtime: "The end of time",
    initialread: "Initial indication (kW·h)",
    winitialread: "Initial indication (m³)",
    endread: "End number (kW·h)",
    phase: "phase",
    wendread: "End number (m³)",
    usepower: "Power consumption (kW · h)",
    loading: "loading...",
    refrashfail: "refrash fail",
    usewater: "Water consumption (m³)",
    total_y: "Total amount (yuan)",
    paymentcontent: "Payment content",
    createtime: "Creation time",
    paymethod: "Method of payment",
    orderstatus: "The order status",
    ordernum: "The order number",
    orderclose: "Order closed",
    waitpay: "wait for payment",
    ispay: "is paying",
    paysuccess: "pay success",
    payfail: "pay fail",
    paycancel: "pay cancel",
    withoutpermission: "without permission",
    payovertime: "pay overtime",
    paywrong: "pay wrong",
    cancelinprograss: "cancel in prograss",
    timeoutinprograss: "timeout in prograss",
    autopaycancel: "auto pay cancel",
    autotimeouthandling: "auto timeout handling",
    payabnormal: "pay abnormal",
    emeterrefresh: "emeter refresh",
    nowfrashdata: "Refresh data this time",
    content: "content",
    operator: "operator",
    contentdetail: "content detail",
    operatime: "opera time",
    administrator: "administrator",
    dealers: "dealers",
    landlord: "landlord",
    sharedRooms: "Number of shared rooms",
    sharedDetail: "shared detail",
    tabletype: "table type",
    sharedRoom: "shared rooms",
    setsuccess: "set success",
    detail: "detail",
    edit: "edit",
    shareproportion: "share proportion",
    house: "house",
    networkstate: "network state",
    isnetwork: "is network",
    notnetwork: "not network",
    networksuccess: "network success",
    networksuccess: "The device under the concentrator",
    keynetwork: "a key network",
    success: "success",
    frashwarning: "The device is not online and cannot be refreshed",
    agentmaintenance: "Agent maintenance",
    totalamount: "Total",
    ordertype: "Order type",
    orderlist: "Order list",
    orderdetail: 'Order detail',
    userinfo: "User info",
    ordermanage: "Order manage",
    ordernumber: "Order number",
    orderamount: "Order amount",
    orderamountyuan: "Order amount(yuan)",
    receiver: "Receiver",
    productionnumber: "Production Number",
    boxnumber: "Box Number",
    commaddress: "Comm address",
    undistribute: "Not distribute",
    distributetofran: "Distribute to fran",
    distributetoland: "Distribute to land",
    retreat: "Retreat",
    precisesearch: "Precise search",
    distribute: "Distribute",
    cartonboxnumber: "Carton box number",
    bonusconfig: "Bonus config",
    bankcardlimit: "Bankcard limit",
    accountmanagement: "account management",
    businesscenter: "business center",
    platformconfiguration: "platform configuration",
    rightsmanagement: "rights management",
    bankcardlimit: "Bankcard limit",
    bankmanagement: "Bank management",
    annualfeestatistics: "annual fee statistics",
    paramconfig: "Param Config",
    trafficmonitoring: "traffic monitoring",
    feeconfig: "Fee Config",
    rechargelimit: "Recharge Limit",
    invoicecode: "Invoice code",
    billingdate: "Billing date",
    checkcode: "Check code",
    goodsortaxableandservices: "Name of goods or taxable services and services",
    specificationandmodel: "Specification and model",
    company: "company",
    amount: "amount",
    unitprice: "Unit Price",
    amountofmoney: "amount of money",
    taxrate: "tax rate",
    taxamount: "Tax amount",
    confirminvoicing: "Confirm Invoicing",
    total2: "total",
    invoicelist: "Invoice list",
    platform: "platform",
    enterinvoiceinformation: "Please complete the invoice information",
    ordinaryinvoice: "Ordinary invoice",
    vatspecialinvoice: "VAT special invoice",
    inapplication: "In application",
    annualfeemanagement: "Annual fee management",
    usernickname: "User nickname",
    numberaccessdevices: "Number of access devices",
    annualunitpriceequipment: "Annual unit price / equipment",
    totalamount2: "Total amount",
    deadline: "deadline",
    paymenttime: "Payment time",
    remarks: "remarks",
    paid: "paid",
    modify: "modify",
    confirm: "confirm",
    account: "account",
    annualfeerecordmodification: "Annual fee record modification",
    cannotbeempty: "Cannot be empty",
    unpaid: "unpaid",
    paying: "paying",
    overdue: "overdue",
    positivenumber: "Positive number",
    accounttype: "account type",
    distributor: "distributor",
    phoneoraccount: "Mobile phone number or account number",
    newaccount: "New account",
    accountname: "Account name",
    creationdate: "Creation date",
    recentlogin: "Recent login",
    realnameinformation: "Real name information",
    details: "details",
    newsuccess: "New successfully",
    basicaccountinformation: "Basic account information",
    collectionpaymentaccount: "Collection and payment account number",
    addressinformation: "Address information",
    dealerinformation: "Dealer information",
    ownerinformation: "Owner information",
    tenantinformation: "Tenant information",
    tenantinformation2: "Tenant information",
    only: "only",
    contactaddress: "Contact address",
    email: "E-mail",
    latestlogin: "Latest login",
    accountstatus: "Account status",
    resetpassword: "reset password",
    deactivate: "deactivate",
    sureto: "Are you sure",
    reset: "reset",
    passwordofaccount: "password of account",
    accountask: "account number",
    afterresetdefaultpassword: "After reset, the default password is",
    deactivationaccountavailable: "After deactivation, the account will not be available",
    bankcollectionaccount: "Bank collection account number",
    bankpaymentaccount: "Bank payment account number",
    wechatcollectionaccount: "Wechat collection account",
    alipayaccountsreceivable: "Alipay accounts receivable",
    clickopenownerpermission: "Click to open the owner's permission",
    openowner: "Opening owner",
    authorityowner: "authority of the owner",
    openownerpermission: "open the owner's permission",
    annualfeeadjustment: "Annual fee adjustment",
    normal: "normal",
    realnamealready: "Real name already",
    norealname: "No real name",
    thisaccountdeactivated: "This account has been deactivated",
    bound: "bound",
    unbound: "unbound",
    clickviewdetails: "Click to view details",
    equipmentleasing: "Equipment leasing",
    inexecution: "In execution",
    abnormal: "abnormal",
    username: "user name",
    usernameoraccount: "user name or account number",
    leasemode: "Lease mode",
    contractstatus: "Contract status",
    contractname: "Contract name",
    totalleaseamountyuan: "Total lease amount (yuan)",
    paidamountyuan: "Paid amount (yuan)",
    numbercurrentperiods: "Number of current periods",
    monthlyrentmode: "Monthly rent mode",
    divisionmode: "Division mode",
    notstarted: "Not started",
    ineffect: "In effect",
    exceptionexecuting: "Exception (executing)",
    exceptionexpiration: "Exception (expiration)",
    end: "end",
    terminated: "terminated",
    electricitysharing: "Electricity sharing",
    flow: "flow",
    noteffective: "Not effective",
    hasended: "Has ended",
    terminatedend: "Terminated (end)",
    annualopeningfee: "Annual opening fee",
    annualfeefunction: "Annual fee function",
    annualfeeamount: "Annual fee amount",
    yuanyear: "Yuan / year",
    statement1: "The annual fee amount will take effect immediately after modification, but it will not affect the current effective annual fee contract",
    owner: "owner",
    tenant2: "tenant",
    termination: "termination",
    expediting: "expediting",
    performancerecord: "Performance record",
    viewscheme: "View scheme",
    totallease: "Total lease",
    splitperdegree: "Split per degree",
    numberleaseterms: "Number of lease terms",
    modificationscheme: "Modification scheme",
    rolename: "Role name",
    number2: "number",
    newrole: "New role",
    correct: "correct",
    operationlog: "Operation log",
    loginlog: "Login log",
    dailyoperation: "Daily operation",
    platformmanagement: "Platform general management",
    namemobilenumber: "Name or mobile number",
    operationtype: "Operation type",
    ipaddress: "IP address",
    operationterminal: "Operation terminal",
    operationcategory: "Operation category",
    operationdetails: "Operation details",
    visited: "Visited",
    pageaccess: "Page access",
    authorizedaccount: "Authorized account",
    totalpricetax: "Total price and tax",
    accountnumberauthorized: "Account number to be authorized",
    account2: "account",
    bindingsucceeded: "Binding succeeded",
    bindingfailed: "Binding failed",
    addadministrator: "Add administrator",
    administratorname: "Administrator name",
    administratorphone: "Administrator phone",
    administratorrole: "Administrator role",
    suredelete: "Are you sure to delete",
    modificationfailed: "Modification failed",
    failedtoadd: "Failed to add",
    successfullyadded: "Successfully added",
    privilegegrantfailed: "privilege grant failed",
    authorizationsucceeded: "Authorization succeeded",
    deletionfailed: "Deletion failed",
    currentrole: "Current role",
    roleaddaccount: "Role add account",
    modifyrole: "Modify role",
    bindaccount: "Bind account",
    delete: "delete",
    authorization: "authorization",
    function: "function",
    resources: "resources",
    systemmanagement: "system management",
    splitPerDegreeLimit: "The amount of each degree is entered incorrectly, ranging from 0.01 to 0.5 yuan",
    startTimeLimit: "Time cannot be less than next month",
    monthlyrent: "Monthly rent",
    addcontract: "Add contract",
    viewcontract: "View contract",
    modifycontract: "Modify the contract",
    terminationcontract: "termination of contract",
    endscheme1: "Total current contract lease amount",
    endscheme2: "yuan, paid up to now",
    endscheme3: "yuan, has the remaining amount been paid?",
    terminationsuccessful: "Termination successful",
    expeditingscheme1: "Please select the reminder notice method",
    platformexpediting: "Platform expediting",
    smsreminder: "SMS reminder",
    wechatexpediting: "Wechat expediting",
    paymentrecord: "Payment record",
    numbercontractperiods: "Number of contract periods",
    date: "date",
    payableamount: "Payable amount (yuan)",
    paidinamount: "Paid in amount (yuan)",
    totalperiods: "Total number of periods",
    paymentmonth: "Payment month",
    paymentchannel: "Payment channel",
    correspondingperiods: "periods",
    paymentorderno: "order No",
    default: "default",
    useraccountnumber: "user account number",
    usernickname: "user nickname",
    ipaddress: "IP address",
    operationterminal: "operation terminal",
    operationtime: "operation time",
    operationcategory: "operation category",
    operationdetails: "operation details",
    enternameormobile: "Please enter your name or mobile number",
    operationtype: "operation type",
    selectdatetime: "select date time",
    monthwater: "month water",
    totalwater: "total water",
    equiptitle: "Equipment lease contract information",
    contractname: "The name of the contract",
    leasemode: "lease mode",
    amonth: "amonth",
    traffic: "traffic",
    rent: "rent",
    yuanm3: "yuan/m³",
    monthpower: "month power",
    address: "address",
    withoutany: "withoutany",
    divided: "divided",
    phone1: "phone",
    tenantname: "tenant name",
    houselist: "house list",
    totalrent: "total rent",
    monthrent: "month rent",
    leaseperiod: "lease period",
    leasetime: "lease time",
    lease: "lease",
    agree: "agree",
    eachdegree: "Each degree is divided into",
    existingaccount: "existing account",
    loginimmediately: "login immediately",
    tosendverification: "Please send the verification code first",
    clicktosendverification: "send the verification code",
    findpassword: "find password",
    accountnotexist: "Account does not exist.",
    rememberpassword: "Remember the password",
    equipment: "Equipment",
    softversion: "Software version number",
    hardversion: "Hardware version number",
    selectDay: 'Select date',
    time: 'Time',
    unitPrice: 'Unit price',
    dosage: 'Dosage',
    rechargeAmount: 'Recharge amount',
    value: 'value',
    electrify: 'Electrify',
    poweroff: 'Power off',
    productrelease: "Product release",
    editproduct: "Commodity editor",
    productdetails: "Product details",
    fillname: "Please fill in the name",
    enter2to50words: "Please enter 2 to 50 words",
    enterbetween0and999999g: "Please enter the weight of 0 ~ 9999999g",
    entervipprice: "Please fill in VIP price",
    entermemberprice0to9999: "Please enter the member price of 0 ~ 9999",
    membershipshallnothighersales: "The membership price shall not be higher than the sales price",
    fillsalesprice: "Please fill in the sales price",
    entersalesprice0to9999: "Please enter the sales price of 0 ~ 9999",
    enterinventory0to99999: "Please enter inventory from 0 to 99999",
    enterweight: "Please enter the weight",
    enterinventory: "Please enter inventory",
    selectproductcategory: "Please select a product category",
    selectwhetheraccesscloudplatform: "Please select whether to access the cloud platform",
    up20parameteritemsadded: "Up to 20 parameter items can be added",
    parametername: "Parameter name",
    parametergroupname: "Parameter group name",
    parameterinformation: "parameter information",
    enter1to100words: "Please enter 1 ~ 100 words",
    paidup: "Paid up",
    unpaiddirecttermination: "Unpaid, direct termination",
    selectdevicetype: "Please select a device type",
    goodsonshelf: "Goods on the shelf",
    goodsoffshelf: "Goods off the shelf",
    goodsonshelfask: "Are you sure you want to put this product on the shelf",
    goodsoffshelfask: "Are you sure you want to take this product off the shelf",
    warehousing: "Warehousing",
    exwarehouse: "Ex warehouse",
    adjustment: "Adjustment",
    tradename: "Trade name",
    warehouseinoutdetails: "In and out warehouse details",
    freight: "freight",
    modifyprice: "modify price",
    paidin: "Paid in",
    wanttocontinueediting: "There are uncompleted publications of published goods. Do you want to continue editing",
    editeditemdoyoucontinue: "There are unfinished edits in the edited item. Do you want to continue",
    doyoucompleteorder: "Do you want to complete the order",
    doyoucloseorder: "Do you want to close the order",
    doyoucompleteofflinepayment: "Do you want to complete offline payment",
    priceadjustmentrecord: "Price adjustment record",
    adjustmenttime: "Adjustment time",
    adjustmentcontent: "Adjustment content",
    orderdetails: "order details",
    price: "price",
    paymenthandlingcharges: "Payment of handling charges",
    quantityreturned: "Quantity returned",
    consigneeinformation: "Consignee information",
    paymentinformation: "Payment information",
    orderinformation: "Order information",
    fillcorrectproductinformation: "Please fill in the correct product information",
    distributionmanagement: "Distribution management",
    failurereason: "Failure reason",
    batchdelete: "Batch delete",
    batchshelves: "Batch shelves",
    batchoffshelf: "Batch off shelf",
    suredeleteproductsbatch: "Are you sure you want to delete these products in batch",
    surewantputproductsshelvesbatches: "Are you sure you want to put these products on the shelves in batches",
    surewantproductsoffshelvesbatches: "Are you sure you want to take these products off the shelves in batches",
    logisticsinformation: "Logistics information",
    nologisticsinformation: "No logistics information",
    logisticsstatus: "Logistics status",
    logisticsname: "Logistics name",
    shipped: "Shipped",
    intransit: "In transit",
    sending: "Sending",
    receivedgoods: "Received goods",
    addressandmobilenumber: "Address and mobile number",
    multipleinvoices: "Multiple invoices",
    invoiceornot: "Invoice or not",
    noinvoicing: "No Invoicing",
    currentordernotcompleted: "Current order not completed",
    copysucceeded: "Copy succeeded",
    lastdigits: "last digits of a number",
    clearrealname: "Clear real name",
    checkclearrealname: "Are you sure you want to clear the real name",
    unbindingcollectionaccountnumber: "Unbinding of collection account number",
    sureunbindingcollectionaccountnumber: "Are you sure you want to unbind the collection account",
    unbindingpaymentaccount: "Unbinding payment account",
    sureunbindingpaymentaccount: "Are you sure you want to unbind the payment account",
    wechatunbinding: "Wechat unbinding",
    surewechatunbinding: "Are you sure you want to unbind wechat",
    alipayuntying: "Alipay untying",
    surealipayuntying: "Are you sure you want to unbind Alipay",
    cardholder: "Cardholder",
    corporateaccount: "Corporate account",
    banknumber: "Bank number",
    wechatmerchantno: "Wechat merchant No",
    alipayaccount: "Alipay account",
    clearedsuccessfully: "Cleared successfully",
    unbindingsucceeded: "Unbinding succeeded",
    titleofaccount: "title of account",
    selfmentionsuccessful: "Self mention successful",
    sureunbindaccount: "Are you sure you want to unbind the account",
    suredeleteaccount: "Are you sure you want to delete the account",
    authorizedaccount2: "Authorized account",
    telephonenumber: "Telephone number",
    addaccount2: "Add account",
    bindingrole: "Binding role",
    delingrole: "Binding role",
    enter10or12digits: "Please enter 10 or 12 digits",
    enter8digits: "Please enter 8 digits",
    invoicecannotissuedtemporarily: "Invoice cannot be issued temporarily",
    picturenamecannotduplicate: "The picture name cannot be duplicate",
    orderremarks: "Order remarks",
    uploadedinvoice: "Uploaded invoice",
    null: "null",
    clickupload: "Click upload",
    jpgjpegpngbmpuploadedupto5: "Only JPG / jpeg / PNG / BMP files can be uploaded, up to 5",
    invoicingsucceeded: "Invoicing succeeded",
    personal: "personal",
    enterprise: "enterprise",
    oktoremove: "OK to remove",
    exportsaved: "Export records are only saved for 7 days",
    exportinformation: "export information",
    totalnum: "total num",
    exportrecord: "Equipment export record",
    filename: "file name",
    completiontime: "completion time",
    down: "down",
    taskstatus: "Task status",
    equaltotalamountincludingtax: "Total must be equal to total amount including tax",
    banknamecannotblank: "Bank name cannot be blank",
    thebankalreadyexists: "The bank already exists",
    length1to50characterswithoutspacesend: "The length should be 1-50 characters without spaces at the end",
    banknameenglishcannotblank: "Bank name (English) cannot be blank",
    pleaseenterenglish: "Please enter English",
    banknameenglishalreadyexists: "The bank name (English) already exists",
    englishabbreviationcannotempty: "English abbreviation cannot be empty",
    englishabbreviationalreadyexists: "The English abbreviation already exists",
    swiftcodecannotempty: "swift code cannot be empty",
    swiftcodealreadyexists: "The swift code already exists",
    banklist: "Bank list",
    serviceaward: "Service Award",
    yuanorset: "Yuan / set",
    crossyearsettlementfull: "Cross year settlement, full",
    monthspayit: "months pay it",
    application: "application",
    calculatedaccordingnumberequipmentdealerarea: "Calculated according to the number of equipment in the dealer's area",
    rechargebonus: "Recharge bonus",
    tenantdirectcharging: "Tenant direct charging",
    upperlimit: "upper limit",
    rechargebehalfcustomers: "Recharge on behalf of customers",
    rechargebonusmsg: "After the tenant's water and electricity charges are recharged, they will be rewarded in proportion. The default is 0.1%, and the maximum is no more than 1 yuan per transaction.",
    salesbonus: "Sales bonus",
    purchaseamount: "Purchase amount",
    tenthousand: "ten thousand",
    rebate: "rebate",
    dealerpurchaserebate: "Dealer purchase rebate",
    enternumericvalue: "Please enter a numeric value",
    amountmustlessnextlevel: "Purchase amount must be less than the next level",
    amountmustgreaterupperlevel: "Purchase amount must be greater than the upper level",
    amountcannotgreaterthan: "Purchase amount cannot be greater than",
    amountcannotnegative: "Purchase amount cannot be negative",
    groupmanagement: "Group management",
    exportsucceeded: "Export succeeded",
    statusInformation: "status information",
    exporting: "Exporting",
}

let en = deepmerge({ lang }, enLocale, { clone: true })
export default en