import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import deepmerge from 'deepmerge'
let lang = {
    gotoabout: '跳转About',
    welcome_zd: "欢迎登入智电生态！！！",
    tips_pictureformat: "上传头像图片只能是 JPG JPEG GIF BMP 格式!",
    tips_pictureformat2: "上传头像图片只能是 JPG JPEG PNG BMP 格式!",
    tips_picturesize: "上传头像图片大小不能超过 5MB!",
    tips_picturewidth: "上传头像图片尺寸不可小于500 x 500!",
    input_account: "请输入账号",
    input_password: "请输入密码",
    passwordsinconsistent: "两次输入密码不一致!",
    passwordtips: "至少1个大写字母、1个小写字母和1个数字!",
    agree_rule: "注册/登录即表示同意",
    article_zd: "《智电生态隐私规则》",
    login: "登录",
    register: "注册",
    loginlog: "登录日志",
    forgetpassword: "忘记密码",
    limit_password: "长度在 6 到 15 个字符",
    login_success: "登入成功",
    login_fail: "登入失败",
    zdst: "智电生态",
    logout: '退出登录',
    eidtacountinfo: '修改账号信息',
    modifyerror: "名称中不能包含特殊字符",
    accountmessage: "账号消息",
    roommessage: "房间消息",
    Changethepassword: "修改密码",
    originalpassword: "原密码",
    newpassword: "新密码",
    comfirmpassword: "确认密码",
    invalidformat: "格式错误",
    submit: "提交",
    reset: "重置",
    personalcenter: "个人中心",
    comfirmidentity: "为确认身份，我们仍需验证您的安全手机",
    comfirmidentity1: "为确认身份，我们仍需验证您的安全邮箱",
    sendsms: "点击发送短信按钮，将会发送一条短信至手机",
    sendsms1: "点击发送短信按钮，将会发送一条短信至邮箱",
    usesecurephone: "请使用安全手机",
    usesecurephone1: "请使用安全邮箱",
    getsms: "获取短信验证码",
    getsms1: "获取验证码",
    smscode: "验证码",
    smscodesuccess: "验证码发送成功",
    smscodefail: "验证码发送失败",
    smsverificationfail: "验证码验证失败",
    sendmessage: "发送短信",
    resend: "发送验证码",
    phoneregistered: "该手机号已被注册",
    listingnews: "房源消息",
    problemfeedback: "问题反馈",
    homepage: '首页',
    edit1: '修改',
    email: '邮箱',
    feesdetail: '收款明细',
    usedetail: '使用明细',
    starttime: '开始时间',
    endtime: '结束时间',
    startindication: "开始示数",
    selecttime: '请选择日期',
    selectregion: '请选择区域',
    enterdetailaddress: '请输入街道、楼牌号等',
    enterhousename: '请输入房源名称',
    enterroomnumber: '请输入房间数量',
    selecteleprice: '请选择租客房租的电价',
    selectcoldprice: '请选择租客房间的冷水价',
    selecthotprice: '请选择租客房间的热水价',
    housename: "房源名称",
    tenantname: "租客姓名",
    roomname: "房间名称",
    roomnumber: "房间数",
    roomedit: "房间编辑",
    select_house: "请选择房源",
    input_roomname: "请输入房间名称",
    input_roomnumber: "请输入房间数量",
    query: "查询",
    hour: "小时",
    day: "日",
    daytwo: "天",
    month: "月",
    monthtime: "月/次",
    advance: "提前",
    today: "今天",
    yesterday: "昨天",
    beforeweek: "一周前",
    year: "年",
    export: "导出",
    num: "序号",
    energycost: "用电量",
    elefees: "电费",
    add: "添加",
    coldwatercost: "用冷水量",
    coldwaterfees: "冷水费",
    hotwatercost: "用热水量",
    hotwaterfees: "热水费",
    publiccirclefee: "公圈费",
    pubelecost: "公摊电量",
    pubelefees: "公摊电费",
    pubwatercost: "公摊水量",
    pubwaterfees: "公摊水费",
    pubweight: "公摊权重",
    pubcustomize: "自定义公摊",
    subtotal: "费用小计",
    subtotal2: "小计",
    forrent: "待租",
    yuan: "(元)",
    yeargather: "本年收款",
    monthgather: "本月收款",
    all: "全部",
    alipay: "支付宝",
    bankcard: "银行卡",
    wechat: "微信",
    version: "版本号",
    historicaldata: "历史数据",
    poweranalysis: "用电分析",
    wateranalysis: "用水分析",
    prepaidrecord: "充值记录",
    paid: "已缴纳",
    overdue: "已逾期",
    IMEI: "IMEI号",
    SIM: "SIM卡号",
    nomessage: "暂无消息",
    IMEI: "IMEI号",
    SIM: "SIM卡号",
    deposit: "押金",
    qualityperiod: "质保期",
    trafficfree: "流量免费周期",
    trafficmonitoring: "流量监控",
    alipaysmall: "支付宝小程序",
    wechatsmall: "微信小程序",
    alipayappletscancodepayment: "支付宝小程序扫码支付",
    wechatappletcodescanningpayment: "微信小程序扫码支付",
    vrpay: "虚拟支付",
    payway: "收款方式",
    select_payway: "请选择收款方式",
    showproject: "显示内容",
    rent: "房租",
    utilities: "水电费",
    other: "其他",
    useraccount: "用户账号",
    cellphonenumber: "手机号",
    enteraccountorphone: "请输入用户账号或手机号",
    accountbankandaccount: "开户行及账号",
    phone: "电话",
    paymentofarrears: "缴纳欠费",
    utilities: "水电费",
    flowfee: "流量费",
    loading: "加载中...",
    equipmentrentalfee: "设备租赁费",
    mallgoods: "商城商品",
    mallmember: "商城会员",
    ownerannualfee: "业主年费",
    other: "其他",
    serialnumber: "序号",
    tenantname: "租客名称",
    tenantnametwo: "房客名称",
    tenantmanagement: "租客管理",
    project: "项目",
    paymentamount: "收款金额",
    paymentmethods: "收款方式",
    paymenttime: "收款时间",
    unknown: "未知",
    unpaid: "未缴纳",
    paying: "缴纳中",
    notselected: "未选定",
    moneyyuan: "金额(元)",
    paymenttimetwo: "支付时间",
    lease: "租赁",
    divided: "分成",
    monthleasemode: "月租模式",
    divided: "电量分成",
    traffic: "流量",
    unknownpage: "未知页面",
    unchoose: "未选择",
    collectiondetails: "收款明细",
    Amountpay: "收款金额",
    total: "总计",
    equipment: "设备",
    logintimeout: "登录超时!",
    requesterror: "网络请求出错!",
    devicemanage: "设备管理",
    devicename: "设备名称",
    assetno: "资产号",
    remarks: "备注",
    cutoffwater: "断水",
    state: "状态",
    subconcentrator: "所属集中器",
    subposition: "所属位置",
    refreshtime: "刷新时间",
    refresh: "刷新",
    modifywatertariff: "修改水电价",
    waterpricemanagement: "水电价管理",
    emeter: "电表",
    emeterrefresh: "电表刷新",
    nowfrashdata: "此次刷新数据",
    pubemeter: "公摊电表",
    wmeter: "水表",
    pubwmeter: "公摊水表",
    coldwmeter: "冷水表",
    hotwmeter: "热水表",
    concentrator: "集中器",
    inputdevicename: "请输入所属位置或资产号",
    operate: "操作",
    operationtype: "操作类型",
    billingdetails: "开票明细",
    details: "详情",
    inputnameandphone: "请输入姓名和手机号",
    myhouse: "我的房源",
    phone: "手机号",
    phoneincorrect: "手机号格式错误",
    emailincorrect: "邮箱格式错误",
    housemanage: "房源管理",
    tenantmanage: "房客管理",
    businesscenter: "财务中心",
    operate: "操作",
    checkdetail: "查看详情",
    area: "地区",
    rooms: "房间数",
    tenantcount: "租客数",
    installposition: "安装位置",
    metercount: "电表数",
    watermetercount: "水表数",
    operationlog: "操作日志",
    operatetime: "操作时间",
    rented: "已出租",
    turnedon: "已开启",
    torent: "出 租",
    stopRent: "退 租",
    edithouse: "修改房源",
    delhouse: "删除房源",
    toinput: "请输入",
    read: "读取",
    temonlybeinteger: "温度值只能为整数",
    temonlybe50to100: "环境温度值需为50 ～ 100的整数",
    datatime: "数据时间",
    notbeempty: "不能为空",
    tochoose: "请选择",
    choosetime: "请选择时间",
    choosetimetwo: "选择时间",
    starttime1: "开始日期",
    endtime1: "结束日期",
    allChoose: "全选",
    duplicaterolename: "角色名称重复!",
    roleName: "角色名称",
    roleDescribe: "角色描述",
    newRole: "新建角色",
    rolemanage: "角色配置",
    tatol: "合计",
    costdetails: "费用详情",
    expensedetailsheet: "费用详细单",
    costyuan: "费用（元）",
    expensetype: "费用类型",
    roominfo: "房间信息",
    publicarea: "公共区域",
    roomstatus: "房间状态",
    haverent: "已出租",
    notrent: "未出租",
    waitforconfirmation: "待确认",
    taxpayeridentificationnumber: "纳税人识别号",
    describe: "描述",
    name: "名称",
    role: "角色",
    name1: "姓名",
    contactway: "联系方式",
    dueTime: "到期时间",
    rentmoney: "租金",
    lastmoney: "水电费余额",
    meter: "电表",
    waterrent: "水费",
    roomwallet: "房间钱包",
    watermeter: "水表",
    status: "状态",
    editname: "修改名称",
    addtenant: "添加租客",
    addtime: "添加时间",
    equipdetail: "设备详情",
    detail1: "详情",
    toview: "查看",
    delroom: "删除房间",
    delete: "删除",
    enteremployee: "请输入员工名称/手机号",
    adddevice: "添加设备",
    coststatistics: "费用统计",
    paymentbill: "缴费账单",
    operaterecord: "操作记录",
    rentalagreement: "租房协议",
    setalarm: "设置告警",
    balanceclear: "水电费余额清零",
    roommanagement: "房间管理",
    addroom: "添加房间",
    equipmentinformation: "设备信息",
    rentwithdrawal: "退租",
    ofthesettlement: "退租结算",
    rentwithdrawalsuccess: "退租成功",
    idnumber: "身份证号",
    nullData: "暂无数据",
    totalelectricity: "累计电费",
    save: "保存",
    savesuccess: "保存成功",
    savefail: "保存失败",
    refrashfail: "刷新失败",
    cumulativewater: "累计水费",
    cumulativeequallyshared: "累计公摊",
    accumulatedtopup: "累计充值",
    rentstartandend: "房租起止时间",
    alarmbalance: "告警余额",
    setalarm: "设置告警",
    balanceclear: "水电费余额清零",
    roommanagement: "房间管理",
    address: "地址",
    set: "设置",
    addroom: "添加房间",
    rent: "出租",
    withoutany: "退租",
    offline: "离线",
    online: "在线",
    poweroutages: "断电",
    electricity: "通电",
    equipmenttype: "设备类型",
    accessdevices: "关联设备数",
    abnormaldevices: "异常设备数",
    apmetername: "公摊表名称",
    apmeterid: "公摊表号",
    waterprice: "水价",
    eleprice_unit: "电价(元/kW·h)",
    waterprice_unit: "水价(元/m³)",
    reminapower_unit: "剩余电量(kW·h)",
    reminapower: "剩余电量",
    totalpower_unit: "总用电量(kW·h)",
    totalpower: "总用电量",
    totalwater: "总用水量",
    monthpower: "本月电量",
    monthwater: "本月水量",
    totalwater_unit: "总用水量(m³)",
    totalusepw: "总用电/水量",
    monthusepw: "本月用电/水量",
    pricepw: "电/水价",
    devicetype: "设备类型",
    ismakesure: "是否确认",
    devicestate: "设备状态",
    pullclosestate: "拉合闸状态",
    more: "更多",
    freshtime: "刷新时间",
    closedswitch: "通电",
    nextprice: "下月生效价格",
    pullswitch: "断电",
    setapportion: "设置公摊",
    untyingdevice: "解绑设备",
    editprice: "修改价格",
    devicereplace: "设备更换",
    eleprice: "电价",
    waterprice: "冷水价",
    hotwaterprice: "热水价",
    newpricetip: "当前显示为生效金额,下月生效金额",
    enterordernumber: "请输入订单号",
    applicationtime: "申请时间",
    invoicetime: "发票时间",
    startdate: "开始日期",
    enddate: "结束日期",
    invoicenumber: "发票号码",
    invoiceheader: "发票抬头",
    invoicestatus: "发票状态",
    invoiceamountyuan: "发票总额(元)",
    addressinformation: "地址信息",
    invoicetype: "发票类型",
    addhouse: "添加房源",
    cancel: "取 消",
    determine: "确 定",
    determine1: "确定",
    edit: "编 辑",
    modify: "修改",
    editsuccess: "修改成功",
    failedtoadd: "新增失败",
    successfullyadded: "新增成功",
    privilegegrantfailed: "授权失败",
    authorizationsucceeded: "授权成功",
    deletionfailed: "删除失败",
    setsuccess: "设置成功",
    delsuccess: "删除成功",
    currentrole: "当前角色",
    remaindaysofrent: "房租剩余天数",
    rentmanagementconfirmation: "房租管理确认",
    rentpaidinfull: "已缴清房租",
    rentmanagementtips: "当前房间房租管理功能未开启，是否要开启？",
    rentmanagementtips1: "如果开启请选择当前房租是否已经缴清。",
    rentmanagementtips2: "未缴清情况下开启房租管理功能，房间会自动断电",
    amountofrentrefundable: "房租应退金额",
    yuan1: "元",
    yuanmonth: "元/月",
    amonth: "个月",
    yuankwh: "元/kW·h",
    yuanm3: "元/m³",
    closedswitch: "通电",
    nextprice: "下月生效价格",
    pullswitch: "断电",
    system: "系统",
    setapportion: "设置公摊",
    systemsettings: "系统设置",
    untyingdevice: "解绑设备",
    editprice: "修改价格",
    priceyuan: "价格（元/kW.h）",
    clearingandstealingelectricity: "清窃电",
    devicereplace: "设备更换",
    unbindsharedequiment: "解绑公摊设备",
    unbindsharedtip: "解绑后以下房间公摊权重将会清零",
    balancetip: "您是否要清除水电费余额?",
    clearornot: "是否清电量",
    thresholdsetting: "阈值设置",
    powerthreshold: "功率阈值",
    accountsettings: "账号设置",
    linetemperature: "线路温度",
    ambienttemperature: "环境温度",
    deviceunbinding: "设备解绑",
    eleprice: "电价",
    coldwaterprice: "冷水价",
    hotwaterprice: "热水价",
    newpricetip: "当前显示为生效金额,下月生效金额",
    addhouse: "添加房源",
    currentmoney: "当前余额",
    cancel: "取 消",
    determine: "确 定",
    determineunbind: "确定解绑",
    sharedweight: "的公摊权重为",
    isresetfactory: "是否恢复出厂设置",
    isclearstealele: "是否清窃电",
    agree: "同 意",
    edit: "编 辑",
    edit2: "编辑",
    unbind: "解绑",
    delay: "延迟",
    complete: "完成",
    alarminformation: "告警信息",
    devicerefresh: "设备刷新",
    editelename: "修改设备名称",
    restorefactorysettings: "恢复出厂设置",
    editsuccess: "修改成功",
    setsuccess: "设置成功",
    delsuccess: "删除成功",
    delfail: "删除失败",
    fail: "失败",
    delconcentrator: "删除集中器",
    tips: "提示",
    isdelconcentrator: "是否删除该集中器",
    isdelhousing: "是否删除该房源",
    isdelroom: "是否删除该房间",
    searchareatip: "请选择或输入地区名字",
    inarea: "所在地区",
    isdel: "是否删除",
    searchareatip: "请选择或输入地区名字",
    inarea: "所在地区",
    houselist: "房源列表",
    notice: "公告",
    detailedaddress: "详细地址",
    number: "数字",
    numberInput: "房间数的范围是1~100",
    addsuccess: "添加成功",
    terminationsuccessful: "终止成功",
    newsuccess: "新建成功",
    room: "房间",
    int: "整数",
    equipmentlist: "设备列表",
    accounttype: "账号类型",
    room: "房间",
    int: "整数",
    equipmentlist: "设备列表",
    tabletype: "表类型",
    current: "电流",
    voltage: "电压",
    factorpower: "功率因数",
    Aphasecurrent: "A相电流(A)",
    Bphasecurrent: "B相电流(A)",
    Cphasecurrent: "C相电流(A)",
    Aphasevoltage: "A相电压(V)",
    Bphasevoltage: "B相电压(V)",
    Cphasevoltage: "C相电压(V)",
    phase: "相",
    powerfactor: "功率因数(W)",
    powerw: "功率(W)",
    startingtime: "起始时间",
    endingtime: "结束时间",
    initialread: "起始示数(kW·h)",
    winitialread: "起始示数(m³)",
    endread: "结束示数(kW·h)",
    wendread: "结束示数(m³)",
    endindication: "结束示数",
    usepower: "用电量(kW·h)",
    usewater: "用水量(m³)",
    total_y: "总额(元)",
    paymentcontent: "缴费内容",
    createtime: "创建时间",
    deadline: "截止时间",
    paymethod: "支付方式",
    orderstatus: "订单状态",
    ordernum: "订单号",
    waitpay: "等待支付",
    ispay: "支付中",
    paysuccess: "支付成功",
    payfail: "支付失败",
    paycancel: "支付取消",
    withoutpermission: "无权限",
    permissionmanage: "权限管理",
    employeeroleassignment: "员工角色分配",
    rolepermissions: "角色权限设置",
    operationlog: "操作日志",
    messagerecord: "消息记录",
    onethousanditemscanbeexported: "最高可导出1000条",
    payovertime: "支付超时",
    paywrong: "支付错误",
    cancelinprograss: "进行中取消",
    timeoutinprograss: "进行中超时",
    autopaycancel: "支付自动取消",
    autotimeouthandling: "超时自动处理",
    payabnormal: "支付异常",
    normal: "正常",
    orderclose: "订单已关闭",
    content: "内容",
    operator: "操作人",
    contentdetail: "详细内容",
    orderclose: "订单已关闭",
    content: "内容",
    phone1: "电话",
    operator: "操作人",
    contentdetail: "详细内容",
    consumptionyuan: "用量（元/kW.h）",
    operatime: "操作时间",
    administrator: "管理员",
    dealers: "管理员",
    landlord: "房东",
    tenant: "房客",
    distributor: "经销商",
    rentmanagementfunction: "房租管理功能",
    rentmanagement: "房租管理",
    sharedRooms: "公摊房间数",
    sharedRoom: "公摊房间",
    sharedDetail: "公摊明细",
    detail: "明细",
    managementexpenseyuan: "管理费（元）",
    shareproportion: "公摊比例",
    house: "房源",
    networkstate: "组网状态",
    isnetwork: "组网",
    alluser: "全部用户",
    makesuredelthisrole: "确定要删除该角色吗？",
    makesuredel: "您是否确认要删除",
    askm: "吗",
    addroles: "添加角色",
    delstaff: "删除员工",
    delroles: "删除角色",
    addemployees: "添加员工",
    employeesname: "员工姓名",
    employeesphone: "员工电话",
    employeesrole: "员工角色",
    checkintime: "入住时间",
    priceedit: "价格修改",
    notnetwork: "未组网",
    networksuccess: "组网成功",
    underconcentractor: "集中器下的设备",
    keynetwork: "一键组网",
    success: "成功",
    frashwarning: "设备不在线，无法进行刷新",
    agentmaintenance: "代客维护",
    totalamount: "总额",
    ordertype: "订单类型",
    orderlist: "订单列表",
    orderdetail: '订单详情',

    mallManage: "商城管理",
    goodsManage: "商品管理",
    goodsComments: "商品评论",
    common: "共",
    strip: "条",
    userinfo: "用户信息",
    ordermanage: "订单管理",
    ordernumber: "订单编号",
    customernumber: "客户编号",
    orderamount: "订单金额",
    orderamountyuan: "订单金额(元)",
    receiver: "收货人",
    productionnumber: "生产订单号",
    boxnumber: "箱号",
    commaddress: "通讯地址",
    address: "地址",
    undistribute: "未分配",
    systemmanagement: "系统管理",
    distributetofran: "已分配经销商",
    distributor: "经销商",
    distributetoland: "已分配房东",
    platformconfiguration: "平台配置",
    retreat: "客退",
    precisesearch: "精确搜索",
    distribute: "分配电表",
    cartonboxnumber: "所属纸箱箱号",
    bonusconfig: "奖励金配置",
    bankcardlimit: "银行卡限额设置",
    accountmanagement: "账号管理",
    dailyoperation: "日常操作",
    platformmanagement: "平台通用管理",
    account: "账户",
    account2: "账号",
    invoicemanagement: "发票管理",
    financialcenter: "财务中心",
    rightsmanagement: "权限管理",
    bankcardlimit: "银行卡限额设置",
    bankmanagement: "银行管理",
    businesscenter: "业务中心",
    annualfeestatistics: "业主年费统计",
    paramconfig: "参数配置",
    feeconfig: "手续费配置",
    rechargelimit: "充值限制",
    invoicecode: "发票代码",
    billingdate: "开票日期",
    checkcode: "校验码",
    goodsortaxableandservices: "货物或应税劳务、服务名称",
    specificationandmodel: "规格型号",
    company: "单位",
    amount: "数量",
    unitprice: "单价",
    amountofmoney: "金额",
    taxrate: "税率",
    taxamount: "税额",
    confirminvoicing: "确认开票",
    confirm: "确认",
    total2: "合计",
    invoicelist: "发票列表",
    platform: "台",
    enterinvoiceinformation: "请完整填写发票信息",
    ordinaryinvoice: "普通发票",
    vatspecialinvoice: "增值税专用发票",
    inapplication: "申请中",
    annualfeemanagement: "经销商年费管理",
    usernickname: "用户昵称",
    numberaccessdevices: "接入设备数",
    annualunitpriceequipment: "年费单价/设备",
    totalamount2: "总金额",
    paymenttime: "缴费时间",
    ipaddress: "IP地址",
    annualfeerecordmodification: "年费记录修改",
    cannotbeempty: "不能为空",
    positivenumber: "正数",
    phoneoraccount: "手机号或账号",
    newaccount: "新建账号",
    accountname: "账号名称",
    creationdate: "创建日期",
    recentlogin: "最近登录",
    realnameinformation: "实名信息",
    basicaccountinformation: "账号基本信息",
    collectionpaymentaccount: "收付款账号",
    dealerinformation: "经销商信息",
    ownerinformation: "业主信息",
    tenantinformation: "房客信息",
    tenantinformation2: "租客信息",
    only: "唯一",
    contactaddress: "联系地址",
    email: "电子邮箱",
    latestlogin: "最新登录",
    accountstatus: "账号状态",
    resetpassword: "重置密码",
    reset: "重置",
    deactivate: "停用",
    sureto: "确定要",
    passwordofaccount: "账号的密码吗",
    accountask: "账号吗",
    inexecution: "执行中",
    afterresetdefaultpassword: "重置后默认密码为",
    deactivationaccountavailable: "停用后账号将不可使用",
    bankcollectionaccount: "银行收款账号",
    bankpaymentaccount: "银行付款账号",
    wechatcollectionaccount: "微信收款账号",
    alipayaccountsreceivable: "支付宝收款账号",
    clickopenownerpermission: "点击开通业主权限",
    openownerpermission: "开通业主权限",
    openowner: "开通业主",
    authorityowner: "的业主权限吗",
    annualfeeadjustment: "年费调整",
    realnamealready: "已实名",
    norealname: "未实名",
    thisaccountdeactivated: "该账号已停用",
    bound: "已绑定",
    unbound: "未绑定",
    bindingsucceeded: "绑定成功",
    bindingfailed: "绑定失败",
    clickviewdetails: "点击查看详情",
    equipmentleasing: "设备租赁",
    abnormal: "异常",
    username: "用户名称",
    usernameoraccount: "用户名称或账号",
    namemobilenumber: "名称或手机号",
    leasemode: "租赁模式",
    contractstatus: "合约状态",
    contractname: "合约名称",
    totalleaseamountyuan: "租赁总金额(元)",
    paidamountyuan: "已缴金额(元)",
    numbercurrentperiods: "当前期数",
    totalperiods: "总期数",
    monthlyrentmode: "月租模式",
    divisionmode: "分成模式",
    notstarted: "未开始",
    ineffect: "生效中",
    exceptionexecuting: "异常(执行中)",
    exceptionexpiration: "异常(到期)",
    end: "结束",
    terminated: "已终止",
    electricitysharing: "电量分成",
    flow: "流量",
    noteffective: "未生效",
    hasended: "已结束",
    terminatedend: "已终止(结束)",
    annualopeningfee: "开通年费",
    annualfeefunction: "年费功能",
    function: "功能",
    resources: "资源",
    annualfeeamount: "年费金额",
    yuanyear: "元/年",
    statement1: "年费金额修改后立即生效，但不影响当前已生效的年费合同",
    owner: "业主",
    tenant2: "租客",
    termination: "终止",
    expediting: "催费",
    performancerecord: "履约记录",
    viewscheme: "查看方案",
    totallease: "租金总额",
    splitperdegree: "每度分成",
    numberleaseterms: "租约期数",
    modificationscheme: "修改方案",
    rolename: "角色名称",
    modifyrole: "修改角色",
    number2: "编号",
    newrole: "新增角色",
    bindaccount: "绑定账号",
    correct: "正确的",
    operationterminal: "操作终端",
    operationcategory: "操作类别",
    operationdetails: "操作详情",
    visited: "访问了",
    pageaccess: "页面访问",
    authorizedaccount: "已授权账号",
    accountnumberauthorized: "待授权账号",
    authorization: "授权",
    totalpricetax: "价税合计",
    addadministrator: "添加管理员",
    administratorname: "管理员名称",
    administratorphone: "管理员电话",
    administratorrole: "管理员角色",
    suredelete: "是否确认删除",
    delete: "删除",
    modificationfailed: "修改失败",
    roleaddaccount: "角色添加账号",
    splitPerDegreeLimit: "每度金额输入错误，范围为0.01~0.5元之间",
    startTimeLimit: "时间不能小于下一个月",
    monthlyrent: "每月租金",
    monthlyrentgreater50: "每月租金必须大于等于50元",
    monthlyrentint: "每月租金只能为整数",
    addcontract: "添加合约",
    viewcontract: "查看合约",
    modifycontract: "修改合约",
    terminationcontract: "合约终止",
    endscheme1: "当前合约租赁总金额",
    endscheme2: "元，截止当前，已缴",
    endscheme3: "元，剩余金额是否已缴清？",
    expeditingscheme1: "请选择催费通知方式",
    platformexpediting: "平台催费",
    smsreminder: "短信催费",
    wechatexpediting: "微信催费",
    paymentrecord: "缴费记录",
    numbercontractperiods: "合约期数",
    date: "日期",
    payableamount: "应缴金额(元)",
    paidinamount: "实缴金额(元)",
    paymentmonth: "缴费月份",
    paymentchannel: "支付渠道",
    correspondingperiods: "对应期数",
    paymentorderno: "支付订单编号",
    default: "默认",

    useraccountnumber: "用户账号",
    usernickname: "用户名称",
    ipaddress: "IP地址",
    operationterminal: "操作终端",
    operationtime: "操作时间",
    time: "时间",
    operationcategory: "操作类别",
    operationdetails: "操作详情",
    enternameormobile: "请输入姓名或手机号",
    operationtype: "操作类型",
    selectdatetime: "选择日期时间",
    equiptitle: "设备租赁合约信息",
    contractname: "合约名称",
    leasemode: "租赁模式",
    totalrent: "租金总额",
    rentlease: "房租租期",
    monthrent: "每月租金",
    monthrenttwo: "月租金",
    remark: "备注",
    powerofftime: "断电时间",
    rentcollectioncycle: "收租周期",
    rentcollectionreminder: "收租提醒",
    leaseperiod: "租约期数",
    leasetime: "租约时间",
    eachdegree: "每度分成",
    existingaccount: "已有账号",
    loginimmediately: "马上登录",
    tosendverification: "请先发送验证码",
    clicktosendverification: "点击获取验证码",
    findpassword: "找回密码",
    accountnotexist: "账号不存在",
    rememberpassword: "记住密码",
    equipment: "设备",
    softversion: "软件版本号",
    hardversion: "硬件版本号",
    selectDay: '选择日期',
    time: '时间',
    unitPrice: '单价',
    dosage: '用量',
    rechargeAmount: '充值金额',
    value: '值',
    electrify: '通电',
    poweroff: '断电',
    productrelease: "商品发布",
    editproduct: "商品编辑",
    productdetails: "商品详情",
    warehouseinoutdetails: "出入库详情",
    fillname: "请填写名称",
    enter2to50words: "请输入2到50个字",
    enterbetween0and999999g: "请输入0~999999克的重量",
    entervipprice: "请填写vip价格",
    entermemberprice0to9999: "请输入0~9999的会员价格",
    membershipshallnothighersales: "会员价不得高于销售价",
    fillsalesprice: "请填写销售价",
    entersalesprice0to9999: "请输入0~9999的销售价",
    enterinventory0to99999: "请输入0~99999的库存",
    enterweight: "请填写重量",
    enterinventory: "请填写库存",
    selectproductcategory: "请选择商品分类",
    selectwhetheraccesscloudplatform: "请选择是否接入云平台",
    up20parameteritemsadded: "最多可添加20个参数项",
    parametername: "参数名称",
    parametergroupname: "参数组名称",
    parameterinformation: "参数信息",
    enter1to100words: "请输入1~100个字",
    paidup: "已缴清",
    unpaiddirecttermination: "未缴清，直接终止",
    selectdevicetype: "请选择设备类型",
    goodsonshelf: "商品上架",
    goodsoffshelf: "商品下架",
    goodsonshelfask: "您确定要上架该商品吗",
    goodsoffshelfask: "您确定要下架该商品吗",
    warehousing: "入库",
    exwarehouse: "出库",
    adjustment: "调整",
    tradename: "商品名称",
    freight: "运费",
    modifyprice: "调价",
    paidin: "实付",
    wanttocontinueediting: "发布商品存在未完成的发布，是否继续编辑",
    editeditemdoyoucontinue: "编辑商品存在未完成的编辑，是否继续",
    doyoucompleteorder: "是否要完成订单",
    doyoucloseorder: "是否要关闭订单",
    doyoucompleteofflinepayment: "是否要完成线下付款",
    priceadjustmentrecord: "调价记录",
    adjustmenttime: "调整时间",
    adjustmentcontent: "调整内容",
    orderdetails: "订单明细",
    price: "价格",
    paymenthandlingcharges: "支付手续费",
    quantityreturned: "已退货数量",
    consigneeinformation: "收货人信息",
    paymentinformation: "支付信息",
    orderinformation: "订单信息",
    fillcorrectproductinformation: "请填写正确的商品信息",
    distributionmanagement: "配表管理",
    failurereason: "失败原因",
    batchdelete: "批量刪除",
    batchshelves: "批量上架",
    batchoffshelf: "批量下架",
    suredeleteproductsbatch: "您确定要批量删除这些商品吗",
    surewantputproductsshelvesbatches: "您确定要批量上架这些商品吗",
    surewantproductsoffshelvesbatches: "您确定要批量下架这些商品吗",
    logisticsinformation: "物流信息",
    nologisticsinformation: "暂无物流信息",
    logisticsstatus: "物流状态",
    logisticsname: "物流名称",
    shipped: "已发货",
    intransit: "运输中",
    sending: "派件中",
    receivedgoods: "已收货",
    tobeinvoiced: "待开票",
    invoiced: "已开票",
    addressandmobilenumber: "地址及手机号",
    multipleinvoices: "多张发票",
    invoiceornot: "是否开票",
    noinvoicing: "不开票",
    currentordernotcompleted: "当前订单未完成",
    copysucceeded: "复制成功",
    lastdigits: "尾号",
    clearrealname: "清除实名",
    checkclearrealname: "您确定要清除实名吗",
    unbindingcollectionaccountnumber: "收款账号解绑",
    sureunbindingcollectionaccountnumber: "您确定要解绑收款账号吗",
    unbindingpaymentaccount: "付款账号解绑",
    sureunbindingpaymentaccount: "您确定要解绑付款账号吗",
    wechatunbinding: "微信解绑",
    surewechatunbinding: "您确定要解绑微信吗",
    alipayuntying: "支付宝解绑",
    surealipayuntying: "您确定要解绑支付宝吗",
    cardholder: "持卡人",
    corporateaccount: "对公账号",
    banknumber: "联行号",
    wechatmerchantno: "微信商户号",
    alipayaccount: "支付宝账号",
    clearedsuccessfully: "清除成功",
    unbindingsucceeded: "解绑成功",
    titleofaccount: "账户名称",
    selfmentionsuccessful: "自提成功",
    sureunbindaccount: "您是否确认要解绑账号",
    authorizedaccount2: "可授权账号",
    telephonenumber: "电话号码",
    addaccount2: "添加账号",
    bindingrole: "绑定角色",
    delingrole: "删除账号",
    suredeleteaccount: "您是否确认要删除账号",
    enter10or12digits: "请输入10位或12位数字",
    enter8digits: "请输入8位数字",
    invoicecannotissuedtemporarily: "暂不能开票",
    picturenamecannotduplicate: "图片名称不能重复",
    orderremarks: "订单备注",
    uploadedinvoice: "已上传发票",
    null: "无",
    clickupload: "点击上传",
    jpgjpegpngbmpuploadedupto5: "只能上传jpg/jpeg/png/bmp文件,最多上传5张",
    invoicingsucceeded: "开票成功",
    personal: "个人",
    enterprise: "企业",
    oktoremove: "确定移除",
    exportinformation: "导出信息",
    exportsaved: "导出记录只保存 7 天",
    exportrecord: "设备导出记录",
    totalnum: "总数",
    filename: "文件名称",
    taskstatus: "任务状态",
    down: "下载",
    createtime: "创建时间",
    completiontime: "完成时间",
    equaltotalamountincludingtax: "合计之和必须等于价税合计",
    banknamecannotblank: "银行名称不能为空",
    thebankalreadyexists: "该银行已存在",
    length1to50characterswithoutspacesend: "长度应在1-50字符且末尾不可有空格",
    banknameenglishcannotblank: "银行名称(英文)不能为空",
    pleaseenterenglish: "请输入英文",
    banknameenglishalreadyexists: "该银行名称(英文)已存在",
    englishabbreviationcannotempty: "英文缩写不能为空",
    englishabbreviationalreadyexists: "该英文缩写已存在",
    swiftcodecannotempty: "swift code不能为空",
    swiftcodealreadyexists: "该swift code已存在",
    banklist: "银行列表",
    serviceaward: "服务奖励金",
    yuanorset: "元/台",
    crossyearsettlementfull: "跨年结算，满",
    monthspayit: "个月给钱",
    application: "应用",
    calculatedaccordingnumberequipmentdealerarea: "按经销商所在区域的设备台数计算",
    rechargebonus: "充值奖励金",
    tenantdirectcharging: "房客直充",
    upperlimit: "上限",
    rechargebehalfcustomers: "代客充值",
    rechargebonusmsg: "房客水电费充值后按比例分成奖励。默认0.1%，最高每笔不超过1元。",
    salesbonus: "销售奖励金",
    purchaseamount: "采购金额",
    tenthousand: "万",
    rebate: "返利",
    dealerpurchaserebate: "经销商采购返利",
    enternumericvalue: "请输入数字值",
    amountmustlessnextlevel: "采购金额必须小于下一级",
    amountmustgreaterupperlevel: "采购金额必须大于上一级",
    amountcannotgreaterthan: "采购金额不能大于",
    amountcannotnegative: "采购金额不能为负数",
    groupmanagement: "商品分类",
    exportsucceeded: "导出成功",
    statusInformation: "状态信息",
    exporting: "导出中",
}
let zh = deepmerge({ lang }, zhLocale, { clone: true })
export default zh