import { post, get, getPro, downPost, exportPost, postUpload, put, deleteRequest } from "../services/http";
import environment from '../environment';
const {
    URL_SERVICE,
    URL_SERVICE2,
    URL_SERVICE_SPRINGSMARTAPS,
    URL_SERVICE_ROOMSERVICE,
    URL_SERVICE_ORDER,
    COMMON_FILE_UPLOAD,
    URL_SERVICE_ADMIN,
    URL_SERVICE_ADMIN3psp,
    URL_SERVICE_B2C,
    URL_SERVICE_USERSYSTEM,
    URL_SERVICE_PC,
    URL_SERVICE_PC_USERSYSTEM,
    URL_SERVICE_PC_ORDER,
    URL_SERVICE_PC_ARTICLE,
    URL_SERVICE_PC_UNIFIED,
    URL_SERVICE_PC_ROOMSERVICE,
    URL_SERVICE_PC_DEVICEHISTORY,
    URL_SERVICE_PC_SPRINGSMARTAPS,
    URL_SERVICE_UNIFIED,
    URL_SERVICE_DEVICEHISTORY,
    URL_SERVICE_ADMIN9,
    URL_SERVICE_SMARTCHARGE,
    URL_SERVICE_SMARTAPS,
    URL_SERVICE_SUPERADMINSERVICE,
    URL_SERVICE_NOTICE
} = environment;
const URL_SMARTAPS = "http://www.wl-s.com:8004/service/smartaps";
export default {
    AC_Login({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SPRINGSMARTAPS + '/AuthService/loginIn', params);
    },
    //智电登入接口
    ZD_Login({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_UNIFIED + '/Account/loginIn', params);
    },
    //统一平台登入接口
    ZD_UserSystemLogin({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/usersystem/login/doLogin', params);
    },
    //账号登录
    ZD_DoLoginAccountV1({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/login/doLoginAccount/v1', params);
    },
    //手机号登录
    ZD_DoLoginPhoneV1({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/login/doLoginPhone/v1', params);
    },
    //获取账户下记录导出列表
    Export_RecordList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/record/fetch', params);
    },

    //导出列表删除
    Export_RecordDel({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/record/delete', params);
    },

    // 示例
    //收款明细年月汇总
    ZD_PaymentStatistic({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ORDER + '/get/v3/Landlord/Receive/Statistic', params);
    },
    //收款明细列表查询
    ZD_PaymentRangeTime({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ORDER + '/v3/Landlord/Receive/GoodList/RangeTime', params);
    },
    //使用和缴费明细获取房源列表
    ZD_DetailHouseList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ROOMSERVICE + '/get/Landlord/Home/House/List', params);
    },

    ZD_GetBoxNumList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/AdminManageService/getBoxNumList', params);
    },
    ZD_GetFranEasyList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/AdminManageService/FranEasyList', params);
    },
    ZD_UndistributeDevList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/manage/device/undistribute/list', params);
    },
    ZD_GetDeviceDefList({ dispatch, commit, state }, params) {
        return get(URL_SERVICE + '/roomservice/DeviceDef');
    },
    ZD_DeviceDefMall({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_ADMIN + '/DeviceDef/mall');
    },
    ZD_GetDeviceTypeList({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_ADMIN + '/device/typedef/DeviceType');
    },
    ZD_DeviceExactList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/manage/device/exact/list', params);
    },
    ZD_DistributionMeter({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/AdminManageService/distributionMeter', params);
    },
    ZD_OrderList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/business/order/list', params);
    },
    // ZD_OrderList({ dispatch, commit, state }, params) {
    //     return post(URL_SERVICE_B2C + '/order/list', params);
    // },
    ZD_OrderLockCheck({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_B2C + '/business/order/lock' + '?' + params);
    },
    ZD_OrderDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/business/order/detail', params);
    },
    ZD_OrderShipping({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/business/order/shipping', params);
    },
    ZD_EditPrice({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/business/order/adjustPrice', params);
    },
    ZD_EditOrderStatus({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/business/order/cancel', params);
    },
    ZD_GetFranid({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/business/order/getFranid', params);
    },
    ZD_GetSysRole({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/authorityModuleList', params);
    },
    ZD_DeliveryCorp({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_B2C + '/business/order/deliveryCorp');
    },
    ZD_RewardInfo({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/reward/total', params);
    },
    ZD_SetServiceReward({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/reward/set/service', params);
    },
    ZD_SetRechargeReward({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/reward/set/recharge', params);
    },
    ZD_SetSellReward({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/reward/set/sell', params);
    },
    ZD_AddBankLimit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/bank/limit/add', params);
    },
    ZD_DelBankLimit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/bank/limit/del', params);
    },
    ZD_ListBankLimit({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/bank/limit/list', params);
    },
    ZD_UpdateBankLimit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/bank/limit/update', params);
    },
    ZD_AddBank({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/bank/add', params);
    },
    ZD_DelBank({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/bank/del', params);
    },
    ZD_ListBank({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/bank/list', params);
    },
    ZD_UpdateBank({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/bank/update', params);
    },
    ZD_FinishOffline({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/business/order/finish-offline', params);
    },
    ZD_ExpressqueryNew({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/order/expressqueryNew', params);
    },


    //商品列表
    ZD_SHOP_PRODUCT_LIST({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/businessProduct/list', params);
    },
    //商品分类列表
    ZD_SHOP_PRODUCT_CAT_LIST({ dispatch, commit, state }) {
        return get(URL_SERVICE_B2C + '/productservice/prductCategory');
    },
    //商品发布
    ZD_SHOP_PRODUCT_PUBLISH({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/businessProduct/product', params);
    },
    //商品x修改
    ZD_SHOP_PRODUCT_MODIFY({ dispatch, commit, state }, params) {
        return put(URL_SERVICE_B2C + '/businessProduct/product', params);
    },
    //商品删除
    ZD_SHOP_PRODUCT_DELETE({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/businessProduct/products', params);
    },
    //商品上架
    ZD_SHOP_PRODUCT_ON_SHELF({ dispatch, commit, state }, params) {
        return put(URL_SERVICE_B2C + '/businessProduct/onShelf', params);
    },
    //商品下架
    ZD_SHOP_PRODUCT_OFF_SHELF({ dispatch, commit, state }, params) {
        return put(URL_SERVICE_B2C + '/businessProduct/offShelf', params);
    },
    //商品入库
    ZD_SHOP_PRODUCT_STOCK_IN({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/businessstock/stock_in', params);
    },
    //商品出库
    ZD_SHOP_PRODUCT_STOCK_OUT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/businessstock/stock_out', params);
    },
    //商品出入库记录
    ZD_SHOP_PRODUCT_STOCK_LOG({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/businessstock/stockLog', params);
    },
    //商品出入库记录更新单号
    ZD_SHOP_PRODUCT_STOCK_ORDER({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/businessstock/stockOutOrder', params);
    },

    //商品详情
    ZD_SHOP_PRODUCT_DETAIL({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_B2C + '/businessProduct?productId=' + params);
    },

    //商品图片上传
    ZD_UPLOAD_FILE({ dispatch, commit, state }, params) {
        return postUpload(COMMON_FILE_UPLOAD, params, { 'Content-Type': 'multipart/form-data' });
    },
    ZD_DEVICE_DEF({ dispatch, commit, state }) {
        return get(URL_SERVICE + '/roomservice/DeviceDef')
    },

    //订单列表
    ZD_PRODUCTGROUP_LIST({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/productgroup/list', params);
    },
    //添加分组
    ZD_PRODUCTGROUP_ADD({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/productgroup/add', params);
    },
    //编辑分组
    ZD_PRODUCTGROUP_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/productgroup/edit', params);
    },
    //获取可分配的商品列表
    ZD_PRODUCTGROUP_PRODUCTLIST({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_B2C + '/productgroup/productlist');
    },
    //删除分组
    ZD_PRODUCTGROUP_DELETE({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_B2C + '/productgroup/delete?' + params);
    },
    //上下架
    ZD_PRODUCTGROUP_PUTONOROFF({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_B2C + '/productgroup/put-on-or-off?' + params);
    },


    /**
     * 权限管理
     * @param {*} URL_SERVICE_USERSYSTEM
     * @param {*} params
     * @returns
     */

    ZD_SysRoleAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/add', params);
    },
    ZD_SysRoleList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/list', params);
    },
    ZD_SysRoleEdit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/edit', params);
    },
    ZD_deleteSysRole({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/delete', params);
    },
    ZD_getAuthModules({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/getAuthModules', params);
    },
    ZD_getAuthObjects({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/getAuthObjects', params);
    },
    ZD_grantRoleModules({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/grantRoleModules', params);
    },
    ZD_grantRoleObjects({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/grantRoleObjects', params);
    },
    ZD_getalluserlist({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysAccountVO/roleList1', params);
    },
    ZD_getSelectuserlist({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/roleAuthAccountList', params);
    },
    ZD_grantRoleAccounts({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/grantRoleAccounts', params);
    },
    //创建管理员帐号
    ZD_CreateAdmin({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/account/create/admin', params);
    },

    /*
     *
     *设备租赁
     * 
     */
    //修改合约
    ZD_ContractModify({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/Contract/modify', params);
    },
    //添加合约
    ZD_ContractAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/Contract/add', params);
    },
    //终止单个合约
    ZD_ContractEnd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/Contract/end', params);
    },
    //终止单个合约并代缴
    ZD_ContractEndAndRepay({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/Contract/endAndRepay', params);
    },
    //单个合约缴费记录
    ZD_ContractRepayList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/Contract/repayList', params);
    },
    //单个合约缴费记录导出
    Export_RepayList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/Lease/Admin/Contract/repayList', params);
    },
    //单个合约履约记录列表
    ZD_ContractDueList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/Contract/dueList', params);
    },
    //单个合约履约记录列表导出
    Export_DueList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/Lease/Admin/Contract/dueList', params);
    },
    //合约催费
    ZD_DunAccount({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/dunAccount', params);
    },
    //真合约催费
    ZD_ContractDun({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/Contract/dun', params);
    },

    // 收款统计
    ZD_CollectionStatistics({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/b2c/business/order/collection/statistics', params);
    },
    // 收款统计
    ZD_CollectionStatisticsDown({ dispatch, commit, state }, params) {
        return downPost(URL_SERVICE + '/b2c/business/order/collection/statistics', params);
    },
    // 收款统计
    ZD_CollectionStatisticsExport({ dispatch, commit, state }, params) {
        return exportPost(URL_SERVICE + '/b2c/business/order/collection/statistics', params);
    },


    /**
     *
     * @param {*} URL_SERVICE_ADMIN adminservice
     * @param {*} params
     * @returns
     */
    // 账号管理--弃用
    // ZD_Admin_AccountList({ dispatch, commit, state }, params) {
    //     return post(URL_SERVICE_ADMIN + '/AdminManageService/v1/AccountList', params);
    // },
    // 账号管理列表
    ZD_Admin_AccountList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/landlord/simplelist', params);
    },
    /** 账号管理 帐号详情*/
    ZD_AccontManageDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/account/detail', params);
    },
    /** 获取经销商详情*/
    ZD_ManageFranDetail({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/manage/fran/detail', params);
    },
    /** 账号管理 帐号列表*/
    ZD_AccontManageList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/account/list', params);
    },
    /** 账号管理 帐号列表导出*/
    Export_AccontList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/account/list', params);
    },
    /** 账号管理 创建账号*/
    ZD_AdminCreate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_UNIFIED + '/Admin/create', params);
    },
    /** 账号管理 重置密码*/
    ZD_AdminResetPWD({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_UNIFIED + '/Admin/ResetPWD', params);
    },
    /** 账号管理 重置密码*/
    ZD_AccontManagePasswordReset({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/account/password/reset', params);
    },
    /** 账号管理 停用账号*/
    ZD_AdminDisableAccount({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_UNIFIED + '/Admin/DisableAccount', params);
    },
    /** 账号管理 启用帐号*/
    ZD_AdminEnableAccount({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_UNIFIED + '/Admin/enableAccount', params);
    },
    /** 账号管理 帐号可用的交易渠道列表*/
    ZD_AccontManageTradeChannel({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/account/tradeChannel/list', params);
    },
    /** 账号管理 扩展业主权限*/
    ZD_AdminExpandLandlord({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_UNIFIED + '/Admin/Expand/Landlord', params);
    },
    // 扩展经销商权限
    ZD_AdminExpandFranchiser({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_UNIFIED + '/Admin/Expand/Franchiser', params);
    },
    // 经销商详情
    ZD_Franchiser_Detail({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/franchiser/detail', params);
    },
    // 续签
    ZD_Franchiser_reSign({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/reSign', params);
    },
    // 奖励统计
    ZD_Franchiser_Statisitic({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/franchiser/reward/statisitic', params);
    },
    // 签约
    ZD_Franchiser_Sign({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/sign', params);
    },
    // 签约记录列表
    ZD_Franchiser_SignList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/sign/list', params);
    },
    // 签约记录列表下载
    Export_SignList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/franchiser/sign/list', params);
    },
    // 签约终止
    ZD_Franchiser_SignStop({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/sign/stop', params);
    },
    // 签约调整
    ZD_Franchiser_SignUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/sign/update', params);
    },
    // 修改经销商信息
    ZD_Franchiser_Update({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/update', params);
    },
    // 增加生效积分
    ZD_Franchiser_validRewardAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/validReward/add', params);
    },
    // 积分变动记录
    ZD_Franchiser_ChangeList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/validReward/change/list', params);
    },
    // 积分变动记录导出
    Export_ChangeList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/franchiser/validReward/change/list', params);
    },
    // 减少生效积分
    ZD_Franchiser_validRewardReduce({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/validReward/reduce', params);
    },

    // 获取指定经销商 设备统计信息
    ZD_Franchiser_deviceStatisitic({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/device/statisitic/byfran', params);
    },
    // 获取配表专用的设备列表
    ZD_Franchiser_forDistributeList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/list/forDistribute', params);
    },
    // 获取配表专用的设备列表 按资产号批量搜索
    ZD_ForDistributeBatch({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/list/forDistribute/batch', params);
    },
    // 配表给经销商
    ZD_Franchiser_distribute({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/distribute', params);
    },
    // 获取指定经销商 已分配的设备列表
    ZD_Franchiser_distributeList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/distribute/list/byfran', params);
    },
    // 获取指定经销商 已分配的设备列表
    ZD_Franchiser_distributeListDownPost({ dispatch, commit, state }, params) {
        return exportPost(URL_SERVICE_ADMIN + '/device/distribute/list/byfran', params);
    },
    // 订单形式智电配表
    ZD_DistributeSubmit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/mall/distribute/submit', params);
    },
    // 智电配表记录
    ZD_DistributeRecord({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/device/mall/distribute/record', params);
    },
    // 我联配表提交
    ZD_DistributeWLSubmit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/distribute/wl/submit', params);
    },
    // 我联配表记录
    ZD_DistributeWLRecord({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/distribute/wl/record', params);
    },


    //设置指定业主的年费配置
    ZD_Admin_AnnualSet({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/landlord/annual/config/set', params);
    },
    //获取指定业主的年费配置
    ZD_Admin_AnnualConfig({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/landlord/annual/config', params);
    },

    //获取业主年费的所有统计信息
    ZD_Admin_AnnualStatistic({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/landlord/annual/statistic', params);
    },

    //获取业主年费的所有统计信息
    ZD_Admin_AnnualList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/landlord/annual/list', params);
    },
    //年费管理导出
    Export_AnnualfeeList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/landlord/annual/list', params);
    },
    //年费管理列表
    ZD_AnnualfeeList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/annualfee/list/page', params);
    },
    //年费管理列表导出
    Export_AnnualListPage({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/annualfee/list/page', params);
    },
    //年费管理修改
    ZD_AnnualfeeUpdate({ dispatch, commit, state }, params) {
        return downPost(URL_SERVICE_ADMIN + '/annualfee/update', params);
    },
    //年费管理已缴纳
    ZD_AnnualfeeFinish({ dispatch, commit, state }, params) {
        return downPost(URL_SERVICE_ADMIN + '/annualfee/finish', params);
    },

    //参数配置
    //获取所有默认值
    ZD_PARAM_GET_TOTAL({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/default/param/total', params);
    },
    //获取水电费告警默认配置
    ZD_BANLANCE_WARN_LIST({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/default/param/banlance/warn', params);
    },
    //修改水电费告警默认配置
    ZD_BANLANCE_WARN_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/default/param/banlance/warn/update', params);
    },
    //获取业主年费默认信息
    ZD_LANDLORD_ANNUAL_lIST({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/default/param/landlord/annual', params);
    },
    //修改业主年费默认信息
    ZD_LANDLORD_ANNUAL_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/default/param/landlord/annual/update', params);
    },
    //获取水电价可输入范围
    ZD_PRICE_RANGE_LIST({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/default/param/price/range', params);
    },
    //修改水电价价格可输入范围
    ZD_PRICE_RANGE_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/default/param/price/range/update', params);
    },
    //获取默认房租逾期天数
    ZD_RENT_OVERDUE_LIST({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/default/param/rent/overdue', params);
    },
    //修改默认房租逾期天数
    ZD_RENT_OVERDUE_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/default/param/rent/overdue/update', params);
    },

    //获取业主充值范围
    ZD_LANDLORD_RANGE({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/landlord/range', params);
    },
    //修改业主充值范围
    ZD_LANDLORD_RANGE_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/landlord/range/update', params);
    },
    //获取业主充值手续费
    ZD_LADDLORD_RATE({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/landlord/rate', params);
    },
    //修改业主充值手续费
    ZD_LADDLORD_RATE_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/landlord/rate/update', params);
    },
    //获取房间水电费每日充值次数
    ZD_ROOM_COUNT({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/room/day/count', params);
    },
    //修改房间水电费每日充值次数
    ZD_ROOM_COUNT_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/room/day/count/update', params);
    },
    //获取房客充值范围
    ZD_TENANT_RANGE({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/tenant/range', params);
    },
    //修改房客充值范围
    ZD_TENANT_RANGE_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/tenant/range/update', params);
    },
    //获取房客充值 房租手续费
    ZD_TENANT_RENT({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/tenant/rent/rate', params);
    },
    //修改房客充值 房租手续费
    ZD_TENANT_RENT_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/tenant/rent/rate/update', params);
    },
    //获取房客充值 水电费手续费
    ZD_TENANT_WALLET({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/tenant/wallet/rate', params);
    },
    //获取房客充值 水电费手续费
    ZD_TENANT_WALLET_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/tenant/wallet/rate/update', params);
    },
    //获取业主充值软件服务费
    ZD_SOFTFEE_RATE({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/landlord/softfee/rate', params);
    },
    //修改业主充值软件服务费
    ZD_SOFTFEE_RATE_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/landlord/softfee/rate/update', params);
    },
    //修改代客充值房租软件服务费
    ZD_RENT_SOFTFEE_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/landlord/rent/softfee/rate/update', params);
    },
    //获取所有充值配置信息
    ZD_RECHARGE_ALL({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/all', params);
    },

    //发票列表
    ZD_InvoiceCenterList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/invoice/list', params);
    },
    //导出发票
    Export_InvoiceList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/b2c/admin/invoice/list', params);
    },
    //真发票详情
    ZD_InvoiceDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/invoice/detail', params);
    },
    //发票详情
    ZD_InvoiceCenterDetail({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_B2C + '/admin/invoice-centre/detail?invoiceListId=' + params.invoiceListId);
    },
    //真开具发票
    ZD_InvoiceIssue({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/invoice/issue', params);
    },
    //开具发票
    ZD_InvoiceCenterUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/invoice-centre/update', params);
    },
    //获取代客充值房租软件服务费
    ZD_RENT_SOFTFEE({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/landlord/rent/softfee/rate', params);
    },
    //修改代客充值房租软件服务费
    ZD_RENT_SOFTFEE_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/landlord/rent/softfee/rate/update', params);
    },

    //代客维护搜索
    ZD_MAINTAIN_SEARCH({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/maintain/search', params);
    },
    //代客维护账号基本信息
    ZD_ACCOUNT_DETAIL({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/maintain/account/detail', params);
    },
    //代客维护账号实名信息
    ZD_CERTIFY_DETAIL({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/maintain/account/certify/info', params);
    },
    //代客维护账号钱包信息
    ZD_WALLET_INFO({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/maintain/account/wallet/info', params);
    },
    //代客维护订单详情
    ZD_ORDER_DETAIL({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/maintain/order/detail', params);
    },
    //代客维护设备基本信息
    ZD_DEVICE_DETAIL({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/maintain/device/detail', params);
    },
    //代客维护设备入库信息
    ZD_DEVICE_STORAGE({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/maintain/device/storage', params);
    },
    //代客维护设备电量信息
    ZD_DEVICE_ENERGYINFO({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/maintain/device/energyInfo', params);
    },
    //代客维护设备历史信息
    ZD_DEVICE_HISTORY({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/maintain/device/historyRecord', params);
    },
    //代客维护设备扩展信息
    ZD_DEVICE_EXTEND({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/maintain/device/extend/info', params);
    },
    //代客维护设备详细信息
    ZD_DEVICE_INFO({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/maintain/device/info', params);
    },

    //设备租赁
    // 合约数量统计
    ZD_ContractNumber({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/Lease/Admin/Contract/number', params);
    },
    // 合约分页列表
    ZD_ContractList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/Lease/Admin/Contract/list', params);
    },
    // 设备租赁导出
    Export_ContractList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/Lease/Admin/Contract/list', params);
    },

    //获取当前账号的权限模块列表
    ZD_RoleAuthModulesList({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_USERSYSTEM + '/sub-account/admin/role-auth-modules-list');
    },
    //VUE版管理员操作日志页面
    ZD_AdminOperateRecordListForVue({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/SystemOperateService/adminOperateRecordListForVue', params);
    },
    //VUE版管理员操作日志页面导出
    Export_AdminOperateRecordListForVue({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/SystemOperateService/adminOperateRecordListForVue', params);
    },
    //添加管理员访问页面日志
    ZD_AddAdminSysOperateLog({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/SystemOperateService/addAdminSysOperateLog', params);
    },
    //代客维护用电分析
    ZD_DeviceAnalysis({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_DEVICEHISTORY + '/maintain/device/analysis', params);
    },
    //--设备列表--用电水分析详情
    ZD_DeviceAnalysisDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_DEVICEHISTORY +
            '/maintain/device/analysis/detail', params);
    },
    //代客维护用电分析导出
    Export_AnalysisDownload({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/devicehistoryservice/maintain/device/analysis', params);
    },
    //代客维护历史数据
    ZD_DeviceHistory({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_DEVICEHISTORY + '/maintain/device/history', params);
    },
    //代客维护历史数据导出
    Export_DeviceHistory({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/devicehistoryservice/maintain/device/history', params);
    },
    //代客维护操作记录
    ZD_DeviceRecordList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/maintain/device/Room/DeviceRecordList', params);
    },
    //代客维护设备通断电
    ZD_DeviceDirectControl({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/manage/device/direct/relay/control', params);
    },

    //入库订单列表 含有可分配表
    ZD_ListFordistribute({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/device/production/order/list/fordistribute', params);
    },
    //入库订单列表
    ZD_ProductionOrderList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/device/production/order/list', params);
    },
    //入库订单详情信息
    ZD_ProductionOrderDetail({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/device/production/order/detail', params);
    },
    //入库订单详情列表
    ZD_ProductionOrderDetailList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/production/order/device/list', params);
    },
    //设备NB注册
    ZD_ProductionOrderNbreg({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/production/order/device/nbreg', params);
    },
    //入库提交
    ZD_ProductionOrderSubmit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/production/order/submit', params);
    },
    //入库检查
    ZD_ProductionOrderCheckOrder({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/device/production/order/checkOrder', params);
    },
    //入库订单删除
    ZD_ProductionOrderDelete({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/production/order/delete', params);
    },
    //入库设备删除
    ZD_ProductionOrderDeviceDelete({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/production/order/device/delete', params);
    },
    //已删除的订单记录详情
    ZD_ProductionOrderDeleteDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/production/order/deleted/detail', params);
    },
    //已删除的订单设备列表
    ZD_ProductionOrderDeviceDeleteList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/production/order/deleted/device/list', params);
    },

    //流量卡订单列表
    ZD_FlowCardOrderList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/flow/card/order/list', params);
    },

    //流量卡订单详情
    ZD_FlowCardOrderDetail({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/flow/card/order/detail', params);
    },
    //流量卡订单详情列表
    ZD_FlowCardOrderDetailList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/flow/card/order/card/list', params);
    },
    //流量卡订单统计
    ZD_FlowCardOrderStatistic({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/flow/card/order/statisitc', params);
    },
    //流量卡订单入库提交
    ZD_FlowCardOrderSubmit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/flow/card/order/submit', params);
    },
    //设备基本信息
    ZD_FlowCardDetail({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/maintain/flow/card/detail', params);
    },
    //结算日期列表
    ZD_OrderDateList({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_ADMIN + '/flow/card/order/date/list?orderid=' + params.orderid);
    },
    //未关联流量卡设备导出 设备入库 未关联设备导出
    Export_NotAssociatedDevice({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/notAssociated/device', params);
    },
    //未关联设备流量卡导出
    Export_NotAssociatedFlowcard({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/notAssociated/flowcard', params);
    },
    //流量卡校验-设备入库
    ZD_FlowCardRefcheck({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/production/order/flow/card/refcheck', params);
    },
    //数据校验
    ZD_StatisticDataCheck({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/flow/card/statistic/dataCheck', params);
    },
    //流量卡校验
    ZD_OrderDeviceRefcheck({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/flow/card/order/device/refcheck', params);
    },
    //流量卡注销校验
    ZD_CardCancelCheck({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/flow/card/order/card/cancel/check', params);
    },
    //批量流量卡注销
    ZD_CardBatchCancel({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/flow/card/order/card/batchCancel', params);
    },
    //批量流量卡注销
    ZD_CardCancelLog({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/flow/card/order/card/cancel/log', params);
    },

    //获取设备类型定义列表
    ZD_DeviceTypedefList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/list', params);
    },
    //获取设备类型定义列表详情
    ZD_DeviceTypedefDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/detail', params);
    },

    //新增电表设备类型定义
    ZD_DeviceMeterAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/meter/add', params);
    },
    //修改电表设备类型定义
    ZD_DeviceMeterUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/meter/update', params);
    },
    //新增插座设备类型定义
    ZD_DeviceStockAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/stock/add', params);
    },
    //修改插座设备类型定义
    ZD_DeviceStockUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/stock/update', params);
    },
    //新增水表设备类型定义
    ZD_DeviceWaterAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/water/add', params);
    },
    //修改水表设备类型定义
    ZD_DeviceWaterUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/water/update', params);
    },
    //新增热水表设备类型定义
    ZD_DeviceHotwaterAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/hotwater/add', params);
    },
    //修改热水表设备类型定义
    ZD_DeviceHotwaterUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/hotwater/update', params);
    },
    //新增集中器设备类型定义
    ZD_DeviceRtuAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/rtu/add', params);
    },
    //修改集中器设备类型定义
    ZD_DeviceRtuUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/rtu/update', params);
    },
    //获取通讯协议列表
    ZD_DeviceProtocolList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/protocol/list', params);
    },

    //新增版本
    ZD_VersionAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/version/add', params);
    },

    //修改版本
    ZD_VersionUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/version/update', params);
    },
    //版本详情
    ZD_VersionDetail({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/device/typedef/version/detail', params);
    },
    //版本列表
    ZD_VersionList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/device/typedef/version/list', params);
    },

    //获取版本功能A
    ZD_VersionAbilityList({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_ADMIN + '/device/typedef/version/abilityList', params);
    },


    /**
     * 
     * @param {*} URL_SERVICE_DEVICEHISTORY    devicehistoryservice
     * @param {*} params 
     * @returns 
     */
    //流量统计数量
    ZD_FlowTotal({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_DEVICEHISTORY + '/flow/total', params);
    },
    //流量统计列表
    ZD_FlowList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_DEVICEHISTORY + '/flow/list', params);
    },

    //流量统计列表
    Export_FlowList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/devicehistoryservice/flow/list', params);
    },
    //流量统计日详情
    ZD_FlowDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_DEVICEHISTORY + '/flow/detail', params);
    },
    //代客维护数据抄读
    ZD_REFRESH({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/AdminManageService/callInstant', params);
    },


    /**
     * 获取当前用户拥有的模块权限列表
     * @param {*} URL_SERVICE_USERSYSTEM  usersystem
     * @param {*} params 
     * @returns 
     */
    /**获取角色列表 */
    USER_SysRoleList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/list', params);
    },
    /**添加角色 */
    USER_RoleAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/add', params);
    },
    /**删除角色 */
    USER_RoleDel({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/delete', params);
    },
    /**点击角色管理查询 获取当前角色能赋予权限的模块，如果已赋予权限selected为true */
    USER_GetAuthModules({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/getAuthModules', params);
    },
    /**点击角色管理保存 */
    USER_GrantRoleModules({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/grantRoleModules', params);
    },
    /**创建子账号 */
    USER_CreateSubAccount({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/createSubAccount', params);
    },
    /**获取角色table展示列表 */
    USER_RoleAuthAccountList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/roleAuthAccountList', params);
    },
    /**可授权管理员子帐号列表 */
    USER_AccountSubaccountList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/account/subaccount/list', params);
    },
    /**删除管理员帐号 */
    USER_AccountDeleteAdmin({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/account/delete/admin', params);
    },
    /**删除员工 */
    USER_StaffDel({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysAccountVO/delete', params);
    },
    /**主账号获取子账号列表 */
    USER_SubaccountList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/subaccount/list', params);
    },
    /**主账号创建子账号 */
    USER_SubaccountCreate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/subaccount/create', params);
    },
    /**主账号删除子账号 */
    USER_SubaccountDelete({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/subaccount/delete', params);
    },
    /**账号绑定角色 */
    USER_SubaccountAccountBindRole({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/subaccount/account-bind-role', params);
    },
    /**获取管理员专用角色列表 */
    USER_SysRoleAdminlist({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/SysRole/adminlist', params);
    },


    //流量费账号
    ZD_TRAFFIC_CREDIT_FEE_LIST({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/DataCreditService/dataCreditFeeList', params);
    },
    //流量费账号-催费方式
    ZD_TRAFFIC_DUN_ACCOUNT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/dunAccount', params);
    },
    //流量费账号-催费
    ZD_TRAFFIC_DUN({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Lease/Admin/Contract/dun', params);
    },
    //流量费账号-导出
    Export_DataCreditFeeList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/DataCreditService/dataCreditFeeList', params);
    },
    //流量费套餐
    ZD_TRAFFIC_CREDIT_LIST({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/DataCreditService/dataCreditList', params);
    },
    //流量费套餐 - 通讯方式下一个生效最大年份
    ZD_TRAFFIC_GET_MAX_YEAR({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/DataCreditService/getMaxYear', params);
    },
    //流量费套餐 - 新增
    ZD_TRAFFIC_CREDIT_ADD({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/DataCreditService/addDataCredit', params);
    },
    //流量费套餐 - 修改
    ZD_TRAFFIC_CREDIT_EDIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/DataCreditService/editDataCredit', params);
    },
    //流量费套餐 - 导出
    Export_DataCreditList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/DataCreditService/dataCreditList', params);
    },

    //微信绑卡列表
    ZD_QUERY_MERCHANT_LIST({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/orderservice/admin/merchantService/queryMerchantList', params);
    },
    //微信绑卡详情
    ZD_QUERY_MERCHANT_DETAIL({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/orderservice/admin/merchantService/queryMerchantDetail', params);
    },

    /**
     *
     * @param {*} URL_SERVICE_ADMIN orderservice2sps
     * @param {*} params
     * @returns
     */
    //管理员获取帐号绑定信息
    ZD_ACCOUNT_BIND_INFO({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ORDER + '/admin/account/bind/info', params);
    },
    //管理员强制解绑帐号实名
    ZD_ACCOUNT_UNBIND_CERTIFY({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ORDER + '/admin/account/unbind/certify', params);
    },
    //管理员强制解绑帐号 银行收款卡
    ZD_ACCOUNT_UNBIND_RECCARD({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ORDER + '/admin/account/unbind/recCard', params);
    },
    //管理员强制解绑帐号 银行付款卡
    ZD_ACCOUNT_UNBIND_PAYCARD({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ORDER + '/admin/account/unbind/payCard', params);
    },
    //管理员强制解绑帐号支付宝
    ZD_ACCOUNT_UNBIND_ALIPAY({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ORDER + '/admin/account/unbind/alipay', params);
    },
    //管理员强制解绑帐号微信(银行卡收款方式)
    ZD_MERCHANTSERVICE_UNBIND({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ORDER + '/admin/merchantService/unbind', params);
    },
    /* ------- 设备升级 -------- */

    // 设备定义列表
    ZD_QUERY_MERTER_LIST({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/AdminManageService/MeterEasyList', params);
    },
    // 设备版本列表
    ZD_QUERY_MERTER_VERSION_LIST({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/device_def_version/list', params);
    },
    // 设备版本列表
    ZD_QUERY_MERTER_VERSION_LIST_BY_ID({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/device_def_version/list_by_id', params);
    },
    // 设备升级列表
    ZD_QUERY_SIMPLE_UPGRADE_LOG_LIST({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/upgrade_log/list', params);
    },
    // 设备升级日志列表
    ZD_UPGRADE_LOG_CANCEL({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/upgrade_log/cancel', params);
    },
    // 设备升级日志列表
    ZD_UPGRADE_LOG_RETRY({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/upgrade_log/retry', params);
    },
    // 设备升级列表
    ZD_UPGRADE_DEVICE_LIST({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/upgrade_meter/list', params);
    },
    // 设备升级列表
    ZD_UPGRADE_DEVICE_SUBMIT({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/upgrade_meter/submit', params);
    },
    // 设备升级详细列表
    ZD_UPGRADE_LOG_DETAIL_LIST({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/upgrade_log/detail/list', params);
    },
    // 我联登录
    ZD_AuthServiceLoginIn({ dispatch, commit, state }, params) {
        return post(URL_SMARTAPS + '/AuthService/loginIn', params);
    },
    // 注销工单列表
    ZD_AccountServiceTicketList({ dispatch, commit, state }, params) {
        return post(URL_SMARTAPS + '/AccountService/ticketList', params);
    },
    // 注销工单详情
    ZD_AccountServiceTicketDetail({ dispatch, commit, state }, params) {
        return post(URL_SMARTAPS + '/AccountService/ticketDetail', params);
    },
    // 注销工单处理审核
    ZD_AccountServiceAuditTicket({ dispatch, commit, state }, params) {
        return post(URL_SMARTAPS + '/AccountService/auditTicket', params);
    },
    // 服务工单列表
    ZD_TicketList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/service/ticket/list', params);
    },
    // 服务工单处理
    ZD_TicketHandle({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/service/ticket/handle', params);
    },
    // 服务工单详情
    ZD_TicketDetail({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/service/ticket/detail', params);
    },
    // 消息工单列表
    ZD_MessageTitleList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/message/ticket/list', params);
    },
    // 消息工单详情
    ZD_MessageTitleDetail({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/message/ticket/detail', params);
    },
    // 消息工单内容列表
    ZD_MessageTitleContentList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/message/ticket/content/list', params);
    },
    // 给消息工单发送一条内容
    ZD_MessageTitleContentSend({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/message/ticket/content/send', params);
    },
    // 结束消息工单
    ZD_MessageTicketFinish({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/message/ticket/finish', params);
    },
    // 设置商品标记
    ZD_OrderFlagSet({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/business/order/flag/set', params);
    },
    //根据token获取经销商信息
    ZD_GetFranchInfoByUserSystemId({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_UNIFIED + '/Fran/getFranchInfoByUserSystemId' + '?id=' + params.id);
    },

    // 获取商城下单 手续费
    ZD_SettingRechargeMallRate({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/mall/rate', params);
    },
    // 修改商城下单 手续费
    ZD_SettingRechargeMallRateUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/mall/rate/update', params);
    },
    // 获取支付渠道手续费
    ZD_SettingRechargeChannelRate({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/channel/rate', params);
    },
    // 修改支付渠道手续费
    ZD_SettingRechargeChannelRateUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/channel/rate/update', params);
    },
    // 获取商城下单 金额范围
    ZD_SettingRechargeMallRange({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/mall/range', params);
    },
    // 修改商城下单 金额范围
    ZD_SettingRechargeMallRangeUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/mall/range/update', params);
    },
    //#region 房间管理
    //房间列表
    ZD_RoomList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Room/List', params);
    },
    //管理员给房间内预付费电表生成token
    ZD_RechargeToken({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/AdminManageService/Room/Meter/rechargeToken', params);
    },
    //给已出租的房间 进行 水电费余额 虚拟充值(会生成订单)
    ZD_AdminOrderSubmit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ORDER + '/v3/Admin/Order/Submit', params);
    },
    //给已出租的房间 进行 房租 虚拟充值(会生成订单)
    ZD_AdminOrderRentSubmit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ORDER + '/v3/Admin/Order/Rent/Submit', params);
    },
    //检查房间内电表的功能
    ZD_RoomCheckAbility({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/Room/check/ability', params);
    },
    //aes电量充值
    ZD_DeviceRechargeAeselec({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Room/device/recharge/aeselec', params);
    },
    //设备清零不跳闸
    ZD_DeviceClearNocut({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/Room/device/clear/nocut', params);
    },
    //#endregion

    /**
     * 运费管理模块
     */
    //管理员获取运费规则信息
    ZD_FreightRuleGet({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/freight/rule/get', params);
    },
    //管理员设置运费规则信息
    ZD_FreightRuleSet({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/freight/rule/set', params);
    },
    //管理员获取运费配置信息
    ZD_FreightConfigGet({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/freight/config/get', params);
    },
    //管理员设置运费配置信息
    ZD_FreightConfigSet({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/freight/config/set', params);
    },
    //自提完成订单
    ZD_OrderSelfDelivery({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/business/order/self-delivery', params);
    },
    //订单商品列表
    ZD_DistributeItemList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/distribute/item/list', params);
    },

    /***
     * 公告管理
     */
    //未发布列表
    ZD_NoticeUnpublishList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/notice/unpublish/list', params);
    },
    //已发布列表
    ZD_NoticePublishList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/notice/publish/list', params);
    },
    //创建公告
    ZD_NoticeCreate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/notice/create', params);
    },
    //获取公告详情
    ZD_NoticeDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/notice/detail', params);
    },
    //修改公告
    ZD_NoticeEdit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/notice/edit', params);
    },
    //删除公告
    ZD_NoticeDelete({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/notice/delete', params);
    },
    //获取单个公告的操作记录列表
    ZD_NoticeOperateList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/notice/detail/operate/list', params);
    },
    //发布公告
    ZD_NoticePublish({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/notice/publish', params);
    },
    //撤销发布
    ZD_NoticeUnPublish({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/notice/unpublish', params);
    },

    /***
     * 经销商管理
     */
    //经销商列表  
    ZD_FranchiserList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/franchiser/list', params);
    },
    //经销商名下业主统计
    ZD_FranchiserLandStatistic({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/franchiser/land/statistic', params);
    },
    //经销商名下业主列表
    ZD_FranchiserLandList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/franchiser/land/list', params);
    },
    //经销商名下设备统计
    ZD_FranchiserDeviceStatistic({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/franchiser/device/statistic', params);
    },
    //经销商名下设备列表
    ZD_FranchiserDeviceList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/device/list', params);
    },
    //获取设备类型定义列表  
    ZD_DeviceTypeList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/device/typedef/list', params);
    },
    //经销商名下迁移记录
    ZD_FranchiserDataMigrateList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/franchiser/data/migrate/list', params);
    },
    //经销商名下迁移
    ZD_FranchiserDataMigrate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/data/migrate', params);
    },
    //经销商名下根据地区统计
    ZD_FranchiserDataStatistic({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/data/statistic', params);
    },
    //经销商名下迁移记录详情
    ZD_FranchiserDataMigrateDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/data/migrate/detail', params);
    },
    //经销商名下单条迁移记录设备明细 
    ZD_FranchiserDataMigrateDetailDeviceList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/data/migrate/detail/device/list', params);
    },
    //经销商名下单条迁移记录业主明细  
    ZD_FranchiserDataMigrateDetailLandList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/franchiser/data/migrate/detail/land/list', params);
    },
    //经销商名下设备列表(导出)
    ZD_FranchiserDeviceListExport({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/franchiser/device/list', params);
    },
    //经销商名下业主列表(导出)
    ZD_FranchiserLandListExport({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/franchiser/land/list', params);
    },

    //#region 个人中心
    //账号详情
    ZD_AccountDetil({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/security/accountDetil', params);
    },
    //修改昵称 
    ZD_UpdateNickName({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/security/updateNickName', params);
    },
    /**修改密码 */
    ZD_ResetPassword({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/security/adminResetPassword', params);
    },
    /**管理员修改自身手机号、邮箱 */
    ZD_AdminUpdatePhoneOrEmail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/security/adminUpdatePhoneOrEmail', params);
    },
    //#endregion
    /**
     * 管理员平台配置-银行管理
     */
    //新增银行信息
    ZD_SettingBankAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/bank/add', params);
    },
    //删除银行信息
    ZD_SettingBankDel({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/bank/del', params);
    },
    //更新银行信息
    ZD_SettingBankUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/bank/update', params);
    },

    /**
     * 财务中心
     */
    //平台充值类型统计
    ZD_TradeTypeStatisitc({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/platform/trade/type/statisitc', params);
    },
    //导出平台充值类型统计
    Export_TypeStatisitc({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/platform/trade/type/statisitc', params);
    },

    //平台手续费统计
    ZD_TradeFeeStatisitc({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/platform/trade/fee/statisitc', params);
    },
    //导出平台手续费统计
    Export_FeeStatisitc({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/platform/trade/fee/statisitc', params);
    },

    //平台充值统计
    ZD_TradeChannelStatisitc({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/platform/trade/channel/statisitc', params);
    },
    //导出平台充值统计
    Export_ChannelStatisitc({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/platform/trade/channel/statisitc', params);
    },
    //平台充值统计详情
    ZD_TradeChannelStatisitcDetail({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/platform/trade/channel/statisitc/detail', params);
    },

    //#region 获取地址模块
    //带区域的地址列表
    ZD_AddressListArea({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_UNIFIED + '/get/common/address/list/area', params);
    },
    //获取地址列表
    ZD_AddressList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_UNIFIED + '/get/common/address/list', params);
    },
    //#endregion

    //#region 评价管理
    //获取模糊匹配
    ZD_CommentUsersGet({ dispatch, commit, state }, params) {
        return get(URL_SERVICE_B2C + '/admin/order/comment/users/get?account=' + params.account);
    },
    //管理员获取评价列表
    ZD_CommentList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/order/comment/list', params);
    },
    //管理员审核评价
    ZD_CommentAudit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/order/comment/audit', params);
    },
    //管理员批量审核评价
    ZD_CommentAuditBatch({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/order/comment/audit/batch', params);
    },
    //管理员回复评价
    ZD_CommentReply({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/order/comment/reply', params);
    },
    //管理员批量回复评价
    ZD_CommentReplyBatch({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/order/comment/reply/batch', params);
    },
    //#endregion

    //#region 供电者管理
    //经销商是否开启充电桩业务
    ZD_AdminFranchiserIsOpen({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/franchiser/isOpen', params);
    },
    //经销商开启充电桩业务
    ZD_AdminFranchiserOpenPile({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/franchiser/open/pile', params);
    },
    //业主是否开启充电桩业务
    ZD_AdminAccountIsOpen({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/account/isOpen', params);
    },
    //业主开启充电桩业务
    ZD_AdminAccountOpenPile({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/account/open/pile', params);
    },
    //供电方列表
    ZD_AdminProviderList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/provider/list', params);
    },
    //供电方账号创建
    ZD_AdminProviderCreate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/provider/create', params);
    },
    //帐号详情
    ZD_AdminProviderDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/provider/detail', params);
    },
    //实名信息
    ZD_AdminProviderCertify({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/provider/certify', params);
    },
    //供电方基本信息
    ZD_AdminProviderBaseInfo({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/provider/baseInfo', params);
    },
    //供电方信息
    ZD_AdminProviderInfo({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/provider/info', params);
    },
    //供电方基本信息录入
    ZD_AdminProviderEntry({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/provider/entry', params);
    },
    //修改供电方基本信息
    ZD_AdminProviderUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/provider/update', params);
    },
    //供电方账单记录查询
    ZD_AdminProviderBillRecord({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/provider/billRecord', params);
    },
    /** 供电方账单记录查询 导出*/
    // Export_ProviderBillRecord({ dispatch, commit, state }, params) {
    //     return post(URL_SERVICE + '/export/smartcharge2/admin/provider/billRecord', params);
    // },
    //#endregion

    //#region 充电桩业务
    //#region 费用配置
    //费用配置信息
    ZD_AdminConfigCostInfo({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/config/cost/info', params);
    },
    //配置修改
    ZD_AdminConfigCostUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/config/cost/update', params);
    },
    //#endregion
    //#region 售电范围配置
    //售电范围信息列表查询
    ZD_AdminConfigPriceList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/config/price/list', params);
    },
    //售电范围配置添加
    ZD_AdminConfigPriceAdd({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/config/price/add', params);
    },
    //售电范围配置删除
    ZD_AdminConfigPriceDelete({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/config/price/delete', params);
    },
    //售电范围配置修改
    ZD_AdminConfigPriceEdit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SMARTCHARGE + '/admin/config/price/edit', params);
    },
    //#endregion
    //#endregion

    //#region 流量卡费用统计
    //流量卡月费用统计
    ZD_StatisticMonth({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/flow/card/statistic/month', params);
    },
    //流量卡年费用统计
    ZD_StatisticYear({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/flow/card/statistic/year', params);
    },
    //费用录入
    ZD_StatisticCostEntry({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/flow/card/statistic/cost/entry', params);
    },
    //流量卡月统计订单详情
    ZD_StatisticMonthDetail({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/flow/card/statistic/month/detail', params);
    },
    //#endregion

    //#region 片区信息
    //片区列表
    ZD_AreaServiceAreaList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SUPERADMINSERVICE + '/areaService/areaList', params);
    },
    //片区连接错误设备列表
    ZD_AreaDeviceErrorList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_SUPERADMINSERVICE + '/areaDeviceErrorService/areaDeviceErrorList', params);
    },
    //#endregion
    //#region 实名认证和代客维护
    //实名信息管理员
    ZD_CertificateInfoShow({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/realName/v2/certificate/admin/info', params);
    },
    //代客维护 房源列表
    ZD_LandHouseList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/maintain/land/house/list', params);
    },
    //经销商状态
    ZD_AccountFranStatus({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/maintain/account/fran/status', params);
    },
    //代客维护 房源内房间列表
    ZD_HouseRoomList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/maintain/land/house/room/list', params);
    },
    //代客维护 房源公共区域设备列表
    ZD_ApporDeviceList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/maintain/land/house/appor/device/list', params);
    },
    //#endregion
    //该接口也可以用于上传文件图片
    ZD_FileUpload({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_NOTICE + '/common/file/upload', params);
    },
    //#region 代客充值手续费
    //获取房客充值 其他手续费
    ZD_TenantOtherRate({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/setting/recharge/tenant/other/rate', params);
    },
    //修改房客充值 其他手续费
    ZD_RentOtherUpdate({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/setting/recharge/tenant/rent/other/update', params);
    },
    //#endregion
    //#region 商城退款、工程商等调整
    //订单金额调整
    ZD_OrderAmountAdjust({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/order/amount/adjust', params);
    },
    //订单退款
    ZD_OrderAmountRefund({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_B2C + '/admin/order/amount/refund', params);
    },
    //待结算积分日记录列表按月
    ZD_CurrentRewardBymonth({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/franchiser/currentReward/list/bymonth', params);
    },
    //订单记录
    ZD_AdminOrderLog({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_B2C + '/admin/order/log', params);
    },
    //待结算积分日记录列表按月
    ZD_ListBymonth({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/get/franchiser/currentReward/list/bymonth', params);
    },
    // 待结算积分日记录列表按月 导出
    Export_ListBymonth({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/franchiser/currentReward/list/bymonth', params);
    },
    //#endregion
    //修改子账号密码
    ZD_ResetSubAccountPassword({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_USERSYSTEM + '/security/resetSubAccountPassword', params);
    },
    //#region 业主免服务费
    //业主免费模式申请记录列表
    ZD_LandlordFreeList({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/landlord/free/list', params);
    },
    //业主免费模式申请记录列表 导出
    Export_LandlordFreeList({ dispatch, commit, state }, params) {
        return post(URL_SERVICE + '/export/adminservice/landlord/free/list', params);
    },
    //业主免费模式审核
    ZD_LandlordFreeCheck({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/landlord/free/check', params);
    },
    //业主免费模式调整
    ZD_LandlordFreeAdjust({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/landlord/free/adjust', params);
    },
    //获取指定业主的免费模式配置
    ZD_LandlordFreeStatus({ dispatch, commit, state }, params) {
        return getPro(URL_SERVICE_ADMIN + '/landlord/free/status', params);
    },
    //申请指定业主开通免费模式
    ZD_LandlordFreeSubmit({ dispatch, commit, state }, params) {
        return post(URL_SERVICE_ADMIN + '/landlord/free/submit', params);
    },
    //#endregion
}