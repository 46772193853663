import i18n from '@/app/i18n/global.js'
import Vue from 'vue'


/**
 * 
 * @param {请求参数} reqParams 
 * @param {*表格titleList详细参考device-manage下的titleList} list 
 * @param {*方法名} methods 
 * @param {*替换} replaceitems //列：{status: {'-1': this.$t('lang.offline'), 0: this.$t('lang.electricity'),'1': this.$t('lang.poweroutages'),},}
 * @param {*下载文件标题} doctitle //列：this.$t('lang.equipmentlist')
 */

Vue.prototype.$down = function (reqParams, list, methods, replaceitems = {}, title = null) {
    try {
        let newObj = JSON.parse(JSON.stringify(reqParams));
        let titleList = []
        list.map(item => {
            titleList.push({
                property: item.prop,
                propertyValue: this.$t(item.label)
            })
        })
        newObj.export = {
            filetype: 1,
            replaceitems: replaceitems,
            titleitems: titleList
        }

        newObj.pageSize = 999999;
        newObj.pageNum = reqParams.pageNum;
        let date = new Date();
        // let title = i18n.t(doctitle);
        let fileName = title + date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDate().toString() + ' ' + date.getHours().toString() + ':' + date.getMinutes().toString() + ':' + date.getSeconds().toString();
        this.$store.dispatch(methods, newObj).then(res => {
            let blob = new Blob([res], { type: "application/json" })
            let objectUrl = URL.createObjectURL(blob);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display:none');
            a.setAttribute('href', objectUrl);
            a.setAttribute('download', fileName + ".xls");
            a.click();
            URL.revokeObjectURL(objectUrl);
        }, error => {
            console.log("!", JSON.parse(error))
        })
    } catch (error) {
        console.log(error);
    }

}

Vue.prototype.$download = function (reqParams, list, methods, replaceitems = {}, title = null, pageSize = 99999) {
    try {
        let newObj = JSON.parse(JSON.stringify(reqParams));
        let titleList = []
        list.map(item => {
            titleList.push({
                property: item.prop,
                propertyValue: this.$t(item.label)
            })
        })
        newObj.export = {
            filetype: 1,
            replaceitems: replaceitems,
            titleitems: titleList
        }

        newObj.pageSize = pageSize;
        newObj.pageNum = 1;
        let date = new Date();
        // let title = i18n.t(doctitle);
        let fileName = title + date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDate().toString() + ' ' + date.getHours().toString() + ':' + date.getMinutes().toString() + ':' + date.getSeconds().toString();
        this.$store.dispatch(methods, newObj).then(res => {
            if (res.success == 1) {
                this.$store.commit("showDownload", true)
            } else {
                console.error("下载失败");
            }
        }, error => {
            console.log("!", JSON.parse(error))
        })
    } catch (error) {
        console.log(error);
    }

}