const Homea = () =>
    import( /* webpackChunkName: "pages" */ './home/Home.vue')
const PaymentDetail = () =>
    import( /* webpackChunkName: "pages" */ './payment-detail/payment-detail.vue')
const AgentMaintenance = () =>
    import( /* webpackChunkName: "pages" */ './agent-maintenance/agent-maintenance.vue')
const OrderList = () =>
    import( /* webpackChunkName: "pages" */ './order-list/order-list.vue')
const HouseDetail = () =>
    import( /* webpackChunkName: "pages" */ './house-detail/house-detail.vue')
const AccountInfo = () =>
    import( /* webpackChunkName: "pages" */ './user-info/account-info.vue')
const GroupManage = () =>
    import( /* webpackChunkName: "pages" */ './group-management/group-management.vue')
const GoodsManage = () =>
    import( /* webpackChunkName: "pages" */ './goods-manage/goods-manage.vue')
const FreightManage = () =>
    import( /* webpackChunkName: "pages" */ './freight-management/freight-management.vue')
const PublishProduct = () =>
    import( /* webpackChunkName: "pages" */ './goods-manage/sub-page/publish-product.vue')
const EditProduct = () =>
    import( /* webpackChunkName: "pages" */ './goods-manage/sub-page/edit-produce.vue')
const GoodsMInOutRecords = () =>
    import( /* webpackChunkName: "pages" */ './goods-manage/sub-page/in-out-records.vue')
const OrderManage = () =>
    import( /* webpackChunkName: "pages" */ './market-order-manage/order-manage.vue')
const EquipmentSelection = () =>
    import( /* webpackChunkName: "pages" */ './market-order-manage/distribution/equipment-selection.vue')
const DistributionMeterWL = () =>
    import( /* webpackChunkName: "pages" */ './market-order-manage/distribution/distribution-meter-wl.vue')
const DistributionMeterRecord = () =>
    import( /* webpackChunkName: "pages" */ './market-order-manage/distribution/distribution-meter-record.vue')
const DistributionMeterRecordWL = () =>
    import( /* webpackChunkName: "pages" */ './market-order-manage/distribution/distribution-meter-record-wl.vue')
const DistributionTable = () =>
    import( /* webpackChunkName: "pages" */ './market-order-manage/distribution/distribution-meter-zd.vue')
const ModifyPriceRecord = () =>
    import( /* webpackChunkName: "pages" */ './market-order-manage/children/modify-price-record.vue')
const BonusConfig = () =>
    import( /* webpackChunkName: "pages" */ './bonus-config/bonus-config.vue')
const RightsManagement = () =>
    import( /* webpackChunkName: "pages" */ './rights-management/rights-management.vue')
const PermissionManage = () =>
    import( /* webpackChunkName: "pages" */ './permission-manage/permission-manage.vue')
const AdNetwork = () =>
    import( /* webpackChunkName: "pages" */ './bonus-config/bonus-config.vue')
const BankcardLimit = () =>
    import( /* webpackChunkName: "pages" */ './bankcard-limit/bankcard-limit.vue')
const BankList = () =>
    import( /* webpackChunkName: "pages" */ './bankcard-limit/bank-list/bank-list.vue')
const InvoiceManagement = () =>
    import( /* webpackChunkName: "pages" */ './invoice-management/invoice-management.vue')
const Accountmanagement = () =>
    import( /* webpackChunkName: "pages" */ './account-management/account-management.vue')
const OperationLog = () =>
    import( /* webpackChunkName: "pages" */ './operation-log/operation-log.vue')
const OperationLogDetail = () =>
    import( /* webpackChunkName: "pages" */ './operation-log/detail.vue')
const AnnualFeeStatistics = () =>
    import( /* webpackChunkName: "pages" */ './business-center/annual-fee-statistics/annual-fee-statistics.vue')
const AnnualFeeManagement = () =>
    import( /* webpackChunkName: "pages" */ './annual-fee-management/annual-fee-management.vue')
const EquipmentLeasing = () =>
    import( /* webpackChunkName: "pages" */ './equipment-leasing/equipment-leasing.vue')
const RecordManage = () =>
    import( /* webpackChunkName: "pages" */ './equipment-leasing/children/record-manage.vue')
const ParamConfig = () =>
    import( /* webpackChunkName: "pages" */ './param-config/param-config.vue')
const FeeConfig = () =>
    import( /* webpackChunkName: "pages" */ './fee-config/fee-config.vue')
const RechargeLimit = () =>
    import( /* webpackChunkName: "pages" */ './recharge-limit/recharge-limit.vue')
const IntegralDetail = () =>
    import( /* webpackChunkName: "pages" */ './account-management/inner-pages/integral-detail.vue')
const SignInfo = () =>
    import( /* webpackChunkName: "pages" */ './account-management/inner-pages/sign-info.vue')
const OrderDetail = () =>
    import( /* webpackChunkName: "pages" */ './order-detail/order-detail.vue')
const TraffiCmonitoring = () =>
    import( /* webpackChunkName: "pages" */ './traffic-monitoring/traffic-monitoring.vue')
const EquipmentType = () =>
    import( /* webpackChunkName: "pages" */ './equipment-type/equipment-type.vue')

const EquipmentVersion = () =>
    import( /* webpackChunkName: "pages" */ './equipment-type/inner-pages/version.vue')
const Traffic = () =>
    import( /* webpackChunkName: "pages" */ './business-center/traffic/traffic.vue')
const CardReviewWechat = () =>
    import( /* webpackChunkName: "pages" */ './card-review-wechat/card-review-wechat.vue')
const EquipmentPutStorage = () =>
    import('./equipment-put-storage/equipment-put-storage.vue')
const EquipmentDetail = () =>
    import('./equipment-put-storage/equipment-detail.vue')
const EquipmentImport = () =>
    import('./equipment-put-storage/equipment-import.vue')
const EquipmentStore = () =>
    import('./equipment-put-storage/equipment-store.vue')
const DataCardManagement = () =>
    import('./data-card-management/data-card-management.vue')
const CardPutStorage = () =>
    import('./data-card-management/card-put-storage.vue')
const DataCardDetail = () =>
    import('./data-card-management/data-card-detail.vue')
const FlowCardStore = () =>
    import('./data-card-management/flow-card-store.vue')
const DeviceUpgrade = () =>
    import( /* webpackChunkName: "pages" */ './device-manage/upgrade/device-upgrade.vue')
const DeviceUpgradeDetail = () =>
    import( /* webpackChunkName: "pages" */ './device-manage/detail/device-upgrade-detail.vue')
const DeviceUpgradeSubmit = () =>
    import( /* webpackChunkName: "pages" */ './device-manage/submit/device-upgrade-submit.vue')
const FranDeviceInfo = () =>
    import( /* webpackChunkName: "pages" */ './account-management/inner-pages/device-info.vue')
const DeviceDistribute = () =>
    import( /* webpackChunkName: "pages" */ './account-management/inner-pages/device-distribute.vue')
const WorkOrderManagement = () =>
    import( /* webpackChunkName: "pages" */ './work-order-management/work-order-management.vue')
const WorkOrderDetail = () =>
    import( /* webpackChunkName: "pages" */ './work-order-management/work-order-detail.vue')
const RoomManagement = () =>
    import( /* webpackChunkName: "pages" */ './room-management/room-management.vue')
const CollectingStatistics = () =>
    import( /* webpackChunkName: "pages" */ './collecting-statistics/collecting-statistics.vue')
const AnnouncementManagement = () =>
    import( /* webpackChunkName: "pages" */ './content-management/announcement-management/announcement-management.vue')
const NewAnnouncement = () =>
    import( /* webpackChunkName: "pages" */ './content-management/announcement-management/new-announcement/new-announcement.vue')
const EditAnnouncement = () =>
    import( /* webpackChunkName: "pages" */ './content-management/announcement-management/edit-announcement/edit-announcement.vue')
const DealerManagement = () =>
    import( /* webpackChunkName: "pages" */ './dealer-management/dealer-management.vue')
const OwnerData = () =>
    import( /* webpackChunkName: "pages" */ './dealer-management/child-page/owner-data.vue')
const DeviceData = () =>
    import( /* webpackChunkName: "pages" */ './dealer-management/child-page/device-data.vue')
const AdjustRecord = () =>
    import( /* webpackChunkName: "pages" */ './dealer-management/child-page/adjust-record.vue')
const MigrationRecord = () =>
    import( /* webpackChunkName: "pages" */ './dealer-management/child-page/migration-record.vue')
const PersonalCenter = () =>
    import( /* webpackChunkName: "pages" */ './personal-center/personal-center.vue')
const BankcardList = () =>
    import( /* webpackChunkName: "pages" */ './bankcard-list/bank-list.vue')
const RechargeTypeStatistics = () =>
    import( /* webpackChunkName: "pages" */ './financial-center/recharge-type-statistics/recharge-type-statistics.vue');
const PlatformRechargeStatistics = () =>
    import( /* webpackChunkName: "pages" */ './financial-center/platform-recharge-statistics/platform-recharge-statistics.vue');
const PlatformFee = () =>
    import( /* webpackChunkName: "pages" */ './financial-center/platform-fee/platform-fee.vue');
const EvaluationManagement = () =>
    import( /* webpackChunkName: "pages" */ './evaluation-management/evaluation-management.vue');
const PowerSupplierManagement = () =>
    import( /* webpackChunkName: "pages" */ './power-supplier-management/power-supplier-management.vue');
const BillingRecords = () =>
    import( /* webpackChunkName: "pages" */ './power-supplier-management/children/billing-records.vue');
const CostAllocation = () =>
    import( /* webpackChunkName: "pages" */ './charging-pile-business/cost-allocation/cost-allocation.vue');
const PriceRangeAllocation = () =>
    import( /* webpackChunkName: "pages" */ './charging-pile-business/price-range-allocation/price-range-allocation.vue');
const FlowCardExpenseStatistics = () =>
    import( /* webpackChunkName: "pages" */ './traffic-management/flowcard-expense-statistics/flowcard-expense-statistics.vue');
const FlowCardInformation = () =>
    import( /* webpackChunkName: "pages" */ './data-card-management/children/flow-card-information.vue');
const FlowCardCancellation = () =>
    import( /* webpackChunkName: "pages" */ './data-card-management/children/flow-card-cancellation.vue');
const FlowcardCancelDetail = () =>
    import( /* webpackChunkName: "pages" */ './traffic-management/flowcard-cancel-detail/flowcard-cancel-detail.vue');
const CancellationCardQuery = () =>
    import( /* webpackChunkName: "pages" */ './traffic-management/cancellation-card-query/cancellation-card-query.vue');
const AreaList = () =>
    import( /* webpackChunkName: "pages" */ './area-information/area-list/area-list.vue');
const AbnormalConnection = () =>
    import( /* webpackChunkName: "pages" */ './area-information/abnormal-connection/abnormal-connection.vue');
const HouseInformation = () =>
    import( /* webpackChunkName: "pages" */ './house-information/house-information.vue');
const EquipmentInfo = () =>
    import( /* webpackChunkName: "pages" */ './equipment/equipment-info.vue');
const FreePayment = () =>
    import( /* webpackChunkName: "pages" */ './business-center/free-payment/free-payment.vue');

export const routes = [
    // 菜单级页面
    {
        path: '/homea',
        name: 'Homea',
        component: Homea
    },
    {
        path: '/groupmanage',
        name: 'GroupManage',
        component: GroupManage,
        meta: {
            title: 'lang.groupmanagement',
            noKeepNav: true
        }
    },
    {
        path: '/goodsmanage',
        name: 'GoodsManage',
        component: GoodsManage,
        meta: {
            title: 'lang.goodsManage',
            noKeepNav: true
        }
    },
    {
        path: '/inoutrecords',
        name: 'InOutRecords',
        component: GoodsMInOutRecords,
        meta: {
            title: "出入库记录",
            noKeepNav: true
        }
    },
    {
        path: '/publishproduct',
        name: 'PublishProduct',
        component: PublishProduct,
        meta: {
            noKeepNav: true,
            title: "lang.productrelease"
        }
    },
    {
        path: '/editproduct',
        name: 'EditProduct',
        component: EditProduct,
        meta: {
            title: "lang.editproduct",
            noKeepNav: true
        }
    },
    {
        path: '/paymentdetail',
        name: 'PaymentDetail',
        component: PaymentDetail,
        meta: {
            title: '付款明细',
            noKeepNav: true
        }
    },
    {
        path: '/agentmaintenance',
        name: 'AgentMaintenance',
        component: AgentMaintenance,
        meta: {
            title: 'lang.agentmaintenance',
            noKeepNav: true
        }
    },
    {
        path: '/orderlist',
        name: 'OrderList',
        component: OrderList,
        meta: {
            title: 'lang.agentmaintenance',
            noKeepNav: true
        }
    },
    {
        path: '/housedetail',
        name: 'HouseDetail',
        component: HouseDetail,
        meta: {
            title: 'lang.agentmaintenance'
        }
    },
    {
        path: '/accountInfo',
        name: 'AccountInfo',
        component: AccountInfo,
        meta: {
            title: '账号信息'
        }
    },
    {
        path: '/ordermanage',
        name: 'OrderManage',
        component: OrderManage,
        meta: {
            title: 'lang.ordermanage',
            noKeepNav: true
        }
    },
    {
        path: '/equipmentSelection',
        name: 'EquipmentSelection',
        component: EquipmentSelection,
        meta: {
            title: "设备选择",
            noKeepNav: true
        }
    },
    {
        path: '/distributionMeterWL',
        name: 'DistributionMeterWL',
        component: DistributionMeterWL,
        meta: {
            title: "我联配表",
            noKeepNav: true
        }
    },
    {
        path: '/distributionMeterRecord',
        name: 'DistributionMeterRecord',
        component: DistributionMeterRecord,
        meta: {
            title: "智电配表记录",
            noKeepNav: true
        }
    },
    {
        path: '/distributionMeterRecordWL',
        name: 'DistributionMeterRecordWL',
        component: DistributionMeterRecordWL,
        meta: {
            title: "我联配表记录",
            noKeepNav: true
        }
    },
    {
        path: '/distributionmeter',
        name: 'DistributionTable',
        component: DistributionTable,
        meta: {
            title: '智电配表'
        }
    },
    {
        path: '/modifypricerecord',
        name: 'ModifyPriceRecord',
        component: ModifyPriceRecord,
        meta: {
            title: '操作记录'
        }
    },
    {
        path: '/bonusconfig',
        name: 'BonusConfig',
        component: BonusConfig,
        meta: {
            title: 'lang.bonusconfig',
            noKeepNav: true
        }
    },
    {
        path: '/bankcardlimit',
        name: 'BankcardLimit',
        component: BankcardLimit,
        meta: {
            title: 'lang.bankcardlimit',
            noKeepNav: true
        }
    },
    {
        path: '/banklist',
        name: 'BankList',
        component: BankList,
        meta: {
            title: 'lang.bankmanagement'
        }
    },
    {
        path: '/paramconfig',
        name: 'ParamConfig',
        component: ParamConfig,
        meta: {
            title: 'lang.paramconfig',
            noKeepNav: true
        }
    },
    {
        path: '/feeconfig',
        name: 'FeeConfig',
        component: FeeConfig,
        meta: {
            title: 'lang.feeconfig',
            noKeepNav: true
        }
    },
    {
        path: '/rightsManagement',
        name: 'RightsManagement',
        component: RightsManagement,
        meta: {
            title: 'lang.rightsmanagement',
        }
    },
    {
        path: '/permissionmanage',
        name: 'PermissionManage',
        component: PermissionManage,
        meta: {
            title: 'lang.permissionmanage',
            noKeepNav: true
        }
    },
    {
        path: '/invoicemanagement',
        name: 'InvoiceManagement',
        component: InvoiceManagement,
        meta: {
            title: 'lang.invoicemanagement',
            noKeepNav: true
        }
    },
    {
        path: '/accountmanagement',
        name: 'Accountmanagement',
        component: Accountmanagement,
        meta: {
            title: 'lang.accountmanagement',
            noKeepNav: true
        }
    },
    {
        path: '/operationlog',
        name: 'OperationLog',
        component: OperationLog,
        meta: {
            title: 'lang.operationlog',
            noKeepNav: true
        }
    },
    {
        path: '/annualfeestatistics',
        name: 'AnnualFeeStatistics',
        component: AnnualFeeStatistics,
        meta: {
            title: 'lang.annualfeestatistics',
            noKeepNav: true
        }
    },
    {
        path: '/annualfeemanagement',
        name: 'AnnualFeeManagement',
        component: AnnualFeeManagement,
        meta: {
            title: 'lang.annualfeemanagement',
            noKeepNav: true
        }
    },
    {
        path: '/equipmentleasing',
        name: 'EquipmentLeasing',
        component: EquipmentLeasing,
        meta: {
            title: 'lang.equipmentleasing',
            noKeepNav: true
        }
    },
    {
        path: '/performancerecord',
        name: 'RecordManage',
        component: RecordManage,
        meta: {
            title: 'lang.performancerecord',
            noKeepNav: true
        }
    },
    {
        path: '/rechargeLimit',
        name: 'RechargeLimit',
        component: RechargeLimit,
        meta: {
            title: 'lang.rechargelimit',
            noKeepNav: true
        }
    },
    {
        path: '/integraldetail',
        name: 'IntegralDetail',
        component: IntegralDetail,
        meta: {
            title: '奖励积分明细',
            noKeepNav: true,
        }
    },
    {
        path: '/signInfo',
        name: 'SignInfo',
        component: SignInfo,
        meta: {
            title: '签约记录'
        },
    },
    {
        path: '/orderdetail',
        name: 'OrderDetail',
        meta: { title: 'lang.orderdetail' },
        component: OrderDetail,
    },
    {

        path: '/trafficmonitoring',
        name: 'TraffiCmonitoring',
        component: TraffiCmonitoring,
        meta: {
            title: 'lang.trafficmonitoring',
            noKeepNav: true
        }
    },
    {

        path: '/equipmentType',
        name: 'EquipmentType',
        component: EquipmentType,
        meta: {
            title: 'lang.equipmenttype',
            noKeepNav: true
        }
    },
    {

        path: '/equipmentVersion',
        name: 'EquipmentVersion',
        component: EquipmentVersion,
        meta: {
            title: '版本信息',
            noKeepNav: true
        },
    },
    {
        path: '/traffic',
        name: 'Traffic',
        component: Traffic,
        meta: {
            title: '流量费管理',
            noKeepNav: true,
        }
    },
    {
        path: '/cardreviewwechat',
        name: 'CardReviewWechat',
        component: CardReviewWechat,
        meta: {
            title: '微信绑卡审核',
            noKeepNav: true,
        }
    },
    {
        path: '/equipmentputstorage',
        name: 'EquipmentPutStorage',
        component: EquipmentPutStorage,
        meta: {
            title: '设备入库',
            noKeepNav: true
        }
    },
    {
        path: '/equipmentdetail',
        name: 'EquipmentDetail',
        component: EquipmentDetail,
        meta: {
            title: '设备订单详情',
            noKeepNav: true
        }
    },
    {
        path: '/equipmentimport',
        name: 'EquipmentImport',
        component: EquipmentImport,
        meta: {
            title: '产品入库'
        }
    },
    {
        path: '/equipmentstore',
        name: 'EquipmentStore',
        component: EquipmentStore,
        meta: {
            title: '产品入库'
        }
    },
    {
        path: '/datacardmanagement',
        name: 'DataCardManagement',
        component: DataCardManagement,
        meta: {
            title: '流量卡管理',
            noKeepNav: true
        }
    },
    {
        path: '/cardputstorage',
        name: 'CardPutStorage',
        component: CardPutStorage,
        meta: {
            title: '流量卡管理入库'
        }
    },
    {
        path: '/datacarddetail',
        name: 'DataCardDetail',
        component: DataCardDetail,
        meta: {
            title: '流量卡订单详情'
        }
    },
    {
        path: '/flowcardstore',
        name: 'FlowCardStore',
        component: FlowCardStore,
        meta: {
            title: '流量卡管理入库',
            noKeepNav: true
        }
    },
    {
        path: '/deviceupgrade',
        name: 'DeviceUpgrade',
        component: DeviceUpgrade,
        meta: {
            title: '批量升级',
            noKeepNav: true
        }
    },
    {
        path: '/deviceupgradesubmit',
        name: 'DeviceUpgradeSubmit',
        component: DeviceUpgradeSubmit,
        meta: {
            title: '批量升级提交',
            noKeepNav: true
        }
    },
    {
        path: '/deviceupgradedetail',
        name: 'DeviceUpgradeDetail',
        component: DeviceUpgradeDetail,
        meta: {
            noKeepNav: true,
            title: '批量升级详情'
        }
    },
    {
        path: '/frandeviceinfo',
        name: 'FranDeviceInfo',
        component: FranDeviceInfo,
        meta: {
            title: '经销商设备信息',
            noKeepNav: true,
        }
    },
    {
        path: '/devicedistribute',
        name: 'DeviceDistribute',
        component: DeviceDistribute,
        meta: {
            title: '经销商设备分配'
        }
    },
    {
        path: '/workordermanagement',
        name: 'WorkOrderManagement',
        component: WorkOrderManagement,
        meta: {
            noKeepNav: true,
            title: '工单管理',
        }
    },
    {
        path: '/workorderdetail',
        name: 'WorkOrderDetail',
        component: WorkOrderDetail,
        meta: {
            title: '工单详情'
        }
    },
    {
        path: '/roommanagement',
        name: 'RoomManagement',
        component: RoomManagement,
        meta: {
            title: '房间管理',
            noKeepNav: true,
        }
    },
    {
        path: '/collectingstatistics',
        name: 'CollectingStatistics',
        component: CollectingStatistics,
        meta: {
            title: '收款统计',
            noKeepNav: true,
        }
    },
    {
        path: '/freightmanage',
        name: 'FreightManage',
        component: FreightManage,
        meta: {
            title: '运费管理',
            noKeepNav: true,
        }
    },
    {
        path: '/operationlogdetail',
        name: 'OperationLogDetail',
        component: OperationLogDetail,
        meta: {
            title: '操作日志-查看详情',
            noKeepNav: true,
        }
    },
    {
        path: '/announcementmanagement',
        name: 'AnnouncementManagement',
        component: AnnouncementManagement,
        meta: {
            title: '公告管理',
            noKeepNav: true,
        }
    },
    {
        path: '/newannouncement',
        name: 'NewAnnouncement',
        component: NewAnnouncement,
        meta: {
            title: '新增公告'
        }
    },
    {
        path: '/editannouncement',
        name: 'EditAnnouncement',
        component: EditAnnouncement,
        meta: {
            title: '编辑公告'
        }
    },
    {
        path: '/dealermanagement',
        name: 'DealerManagement',
        component: DealerManagement,
        meta: {
            title: '经销商管理',
        }
    },
    {
        path: '/ownerdata',
        name: 'OwnerData',
        component: OwnerData,
        meta: {
            title: '业主数据',
        }
    },
    {
        path: '/devicedata',
        name: 'DeviceData',
        component: DeviceData,
        meta: {
            title: '设备数据',
        }
    },
    {
        path: '/adjustrecord',
        name: 'AdjustRecord',
        component: AdjustRecord,
        meta: {
            title: '调整记录',
        }
    },
    {
        path: '/migrationrecord',
        name: 'MigrationRecord',
        component: MigrationRecord,
        meta: {
            title: '调整记录-详情',
            noKeepNav: true,
        }
    },
    {
        path: '/bankcardlist',
        name: 'BankcardList',
        component: BankcardList,
        meta: {
            title: '银行列表',
        }
    },
    {
        path: '/personalcenter',
        name: 'PersonalCenter',
        component: PersonalCenter,
        meta: {
            title: '个人中心',
        },
    },
    {
        path: '/rechargetypestatistics',
        name: 'RechargeTypeStatistics',
        component: RechargeTypeStatistics,
        meta: {
            title: '充值类型统计',
            noKeepNav: true,
        }
    },
    {
        path: '/platformrechargestatistics',
        name: 'PlatformRechargeStatistics',
        component: PlatformRechargeStatistics,
        meta: {
            title: '平台充值统计',
            noKeepNav: true,
        }
    },
    {
        path: '/platformfee',
        name: 'PlatformFee',
        component: PlatformFee,
        meta: {
            title: '手续费明细',
            noKeepNav: true,
        }
    },
    {
        path: '/evaluationmanagement',
        name: 'EvaluationManagement',
        component: EvaluationManagement,
        meta: {
            title: '评价管理',
            noKeepNav: true,
        }
    },
    {
        path: '/powersuppliermanagement',
        name: 'PowerSupplierManagement',
        component: PowerSupplierManagement,
        meta: {
            title: '供电者管理',
            noKeepNav: true,
        }
    },
    {
        path: '/billingrecords',
        name: 'BillingRecords',
        component: BillingRecords,
        meta: {
            title: '账单记录',
            noKeepNav: true,
        }
    },
    {
        path: '/costallocation',
        name: 'CostAllocation',
        component: CostAllocation,
        meta: {
            title: '费用配置',
            noKeepNav: true,
        }
    },
    {
        path: '/pricerangeallocation',
        name: 'PriceRangeAllocation',
        component: PriceRangeAllocation,
        meta: {
            title: '电价范围配置',
            noKeepNav: true,
        }
    },
    {
        path: '/flowcardexpensestatistics',
        name: 'FlowCardExpenseStatistics',
        component: FlowCardExpenseStatistics,
        meta: {
            title: '流量卡费用统计',
            noKeepNav: true,
        }
    },
    {
        path: '/flowcardinformation',
        name: 'FlowCardInformation',
        component: FlowCardInformation,
        meta: {
            title: '流量卡信息',
            noKeepNav: true,
        }
    },
    {
        path: '/flowcardcancellation',
        name: 'FlowCardCancellation',
        component: FlowCardCancellation,
        meta: {
            title: '流量卡注销',
            noKeepNav: true,
        }
    },
    {
        path: '/flowcardcanceldetail',
        name: 'FlowcardCancelDetail',
        component: FlowcardCancelDetail,
        meta: {
            title: '流量卡注销详情',
            noKeepNav: true,
        }
    },
    {
        path: '/cancellationcardquery',
        name: 'CancellationCardQuery',
        component: CancellationCardQuery,
        meta: {
            title: '注销卡查询',
            noKeepNav: true,
        }
    },
    {
        path: '/areaList',
        name: 'AreaList',
        component: AreaList,
        meta: {
            title: '片区列表',
            noKeepNav: true,
        }
    },
    {
        path: '/abnormalConnection',
        name: 'AbnormalConnection',
        component: AbnormalConnection,
        meta: {
            title: '异常连接',
            noKeepNav: true,
        }
    },
    {
        path: '/houseInformation',
        name: 'HouseInformation',
        component: HouseInformation,
        meta: {
            title: '房源信息',
            noKeepNav: true,
        }
    },
    {
        path: '/equipmentInfo',
        name: 'EquipmentInfo',
        component: EquipmentInfo,
        meta: {
            title: '设备信息',
            noKeepNav: true,
        }
    },
    {
        path: '/freePayment',
        name: 'FreePayment',
        component: FreePayment,
        meta: {
            title: '支付免费',
            noKeepNav: true,
        }
    },
]