import axios from "axios";
import router from '../router';
import Vue from 'vue';
import ElementLocale from 'element-ui/lib/locale';
import { MENUTYPE, VENDINGTYPE } from '../app/common/resources/resources.js';
import store from '../store'

function post(url, params, hearder) {
    if (params) {
        params.systemid = MENUTYPE.ADMIN;
        params.vendingtype = VENDINGTYPE.PC;
    }
    return new Promise((resolve, reject) => {
        axios.post(baseUrlHandle(url), params, hearder).then(res => {
            // console.log(res.data);
            if (res.data.success == 6 || res.code == 401) {
                goLogin();
            } else {
                successCheck(res.data, params)
                resolve(res.data);
            }
        }).catch(err => {
            // console.log('posterr>> ', err)
            if (err.data.data) {
                reject(errorHandle(err.data));
            } else {
                reject(errorHandle(err));
            }
        });
    })
}

function get(url, hearder) {
    return new Promise((resolve, reject) => {
        axios.get(baseUrlHandle(url), hearder).then(res => {
            // console.log(res.data);
            if (res.data.success == 6 || res.code == 401) {
                goLogin();
            } else {
                successCheck(res.data, 'params')
                resolve(res.data);
            }
        }).catch(err => {
            console.log(err)
            reject(errorHandle(err.data));
        });
    })
}
function getPro(url, params, hearder) {
    return new Promise((resolve, reject) => {
        axios.get(getUrlHandle(url, params), hearder).then(res => {
            if (res.data.success == 6 || res.code == 401) {
                goLogin();
            } else {
                successCheck(res.data, params)
                resolve(res.data);
            }
        }).catch(err => {
            reject(errorHandle(err.data));
        });
    })
}

//导出专用接口
function downPost(url, params, hearder) {
    let downloadUrl = urlHandle(baseUrlHandle(url));
    // console.log('downloadUrl :>> ', downloadUrl);
    return new Promise((resolve, reject) => {
        axios.post(downloadUrl, params, { responseType: 'blob' },).then(res => {
            // console.log(res);
            resolve(res);
        }).catch(err => {
            console.log(err)
            reject(errorHandle(err.data));
        });
    })
}
//上传专用
function postUpload(url, params, hearder) {
    if (params) {
        params.systemid = MENUTYPE.ADMIN;
        params.vendingtype = VENDINGTYPE.PC;
    }
    return new Promise((resolve, reject) => {
        axios.post(url, params, hearder).then(res => {
            // console.log(res.data);
            // if (res.data.success == 6 || res.code == 401) {
            //     goLogin();
            // } else {
            resolve(res);
            // }
        }).catch(err => {
            // console.log('posterr>> ',err)
            Vue.prototype.$message({
                message: ElementLocale.t('lang.requesterror'),
                type: 'error'
            });
            reject(errorHandle(err.data));
        });
    })
}

function put(url, params, hearder) {
    if (params) {
        params.systemid = MENUTYPE.ADMIN;
        params.vendingtype = VENDINGTYPE.PC;
    }
    return new Promise((resolve, reject) => {
        axios.put(url, params, hearder).then(res => {
            // console.log(res.data);
            // if (res.data.success == 6 || res.code == 401) {
            //     goLogin();
            // } else {
            successCheck(res.data, params)
            resolve(res.data);
            // }
        }).catch(err => {
            // console.log('posterr>> ',err)
            Vue.prototype.$message({
                message: ElementLocale.t('lang.requesterror'),
                type: 'error'
            });
            reject(errorHandle(err.data));
        });
    })
}

function deleteRequest(url, hearder) {
    return new Promise((resolve, reject) => {
        axios.delete(url, hearder).then(res => {
            if (res.data.success == 6 || res.code == 401) {
                goLogin();
            } else {
                resolve(res.data);
            }
        }).catch(err => {
            console.log(err)
            reject(errorHandle(err.data));
        });
    })
}
// url处理 传入 查询接口地址,返回 导出接口地址
function urlHandle(url) {
    let [httpStr, baseUrl] = url.split('//');
    let baseUrlArr = baseUrl.split('/');
    let index = baseUrlArr.findIndex(item => item == 'service');
    baseUrlArr.splice(index + 1, 0, 'download')
    let downloadUrl = baseUrlArr.join('/');
    return httpStr + '//' + downloadUrl;

}

/*地址片区处理**/
function baseUrlHandle(url) {
    if (url.indexOf("zdianyun") == -1) return url;
    if (store.state.userNo == 1) return url;
    return url.replace("1", store.state.userNo);
}

function exportPost(url, params, hearder) {
    let downloadUrl = urlHandleExport(baseUrlHandle(url));
    // console.log('downloadUrl :>> ', downloadUrl);
    return new Promise((resolve, reject) => {
        axios.post(downloadUrl, params, hearder).then(res => {
            // console.log(res.data);
            if (res.data.success == 6 || res.code == 401) {
                goLogin();
            } else {
                successCheck(res.data, params)
                resolve(res.data);
            }
        }).catch(err => {
            // console.log('posterr>> ', err)
            reject(errorHandle(err));
        });
    })
}
// url处理 传入 查询接口地址,返回 导出接口地址
function urlHandleExport(url) {
    let [httpStr, baseUrl] = url.split('//');
    let baseUrlArr = baseUrl.split('/');
    let index = baseUrlArr.findIndex(item => item == 'service');
    baseUrlArr.splice(index + 1, 0, 'export')
    let downloadUrl = baseUrlArr.join('/');
    return httpStr + '//' + downloadUrl;

}

//默认网络请求(通过success == 1判断)错误时,提示后端返回的错误信息;
//网络请求参数带successCheckType时,不做处理与提示,由具体页面自己处理
function successCheck(res, params) {
    if (params.successCheckType) {
        // return res;
    } else {
        if (res.success && res.success != 1) {
            let errordetail = res.errordetail || ElementLocale.t('lang.requesterror');
            Vue.prototype.$message({
                message: errordetail,
                type: 'error'
            });
        }
    }
}


function errorHandle({ data }) {
    let { error, status, message } = data;
    let ret = "";
    switch (status) {
        case 401:
            goLogin();
            break;
        case 404:
            ret = "404 " + '服务未找到';
            break;
        case 500:
            ret = '系统异常';
            break;
        default:
            break;
    }
    status != 401 && Vue.prototype.$message({
        message: ElementLocale.t('lang.requesterror'),
        type: 'error'
    });
    return ret;
}

function goLogin() {
    console.log(router);
    if (router.history.current.name == "Login") {
        return
    }
    router.replace('/login');
    Vue.prototype.$message({
        message: ElementLocale.t('lang.logintimeout'),
        type: 'error'
    });
}

function getUrlHandle(baseUrl, params) {
    let url = baseUrlHandle(baseUrl);
    if (params == undefined || Object.keys(params).length < 1) return url;
    url += "?";
    Object.keys(params).forEach(elem => {
        if (params[elem] == undefined) params[elem] = '';
        url = url + elem + "=" + params[elem] + "&";
    });
    return url.slice(0, url.length - 1);
}


export {
    post,
    get,
    getPro,
    downPost,
    postUpload,
    put,
    deleteRequest,
    exportPost
}