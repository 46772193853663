export default {
    COMMON_FILE_UPLOAD: 'http://116.62.39.45:1899/common/file/upload',
    HOSTHOME: 'https://user0-web.zdianyun.com', //国内天翼云
    URL_USERSYSTEM: 'https://user0-web.zdianyun.com/service/usersystem',
    URL_SERVICE: 'https://user0-web.zdianyun.com/service',
    URL_SERVICE_ORDER: 'https://user0-web.zdianyun.com/service/orderservice',
    URL_SERVICE_NOTICE: 'https://user0-web.zdianyun.com/service/article',
    URL_SERVICE_ADMIN: 'https://user0-web.zdianyun.com/service/adminservice',
    URL_SERVICE_B2C: 'https://user0-web.zdianyun.com/service/b2c',
    URL_SERVICE_USERSYSTEM: 'https://user0-web.zdianyun.com/service/usersystem',
    URL_SERVICE_UNIFIED: 'https://user0-web.zdianyun.com/service/unifiedservice',
    URL_SERVICE_DEVICEHISTORY: 'https://user0-web.zdianyun.com/service/devicehistoryservice',
    URL_SERVICE_SPRINGSMARTAPS: 'https://user0-web.zdianyun.com/service/springsmartapstest',
    URL_SERVICE_ROOMSERVICE: 'https://user0-web.zdianyun.com/service/roomservice',
    URL_SERVICE_ORDERSERVICE2SPS: 'http://www.zdianyun.com:20002/service/orderservice2sps',
    URL_SERVICE_SMARTCHARGE: 'https://user0-web.zdianyun.com/service/smartcharge',
    URL_SERVICE_SMARTAPS: 'https://www.zdianyun.com:8004/service/smartaps',
    URL_SERVICE_SUPERADMINSERVICE: 'https://www.zdianyun.com:20002/service/superadminservice',
    NOPORTHOST: 'http://www.zdianyun.com',

    /**加PC */
    URL_SERVICE_PC: 'https://user0-web.zdianyun.com/service/pc',
    URL_SERVICE_PC_ORDER: 'https://user0-web.zdianyun.com/service/orderservice/pc',
    URL_SERVICE_PC_ARTICLE: 'https://user0-web.zdianyun.com/service/article/pc',
    URL_SERVICE_PC_UNIFIED: 'https://user0-web.zdianyun.com/service/unifiedservice/pc',
    URL_SERVICE_PC_DEVICEHISTORY: 'https://user0-web.zdianyun.com/service/devicehistoryservice/pc',
    URL_SERVICE_PC_SPRINGSMARTAPS: 'https://user0-web.zdianyun.com/service/springsmartapstest/pc',
    URL_SERVICE_PC_ROOMSERVICE: 'https://user0-web.zdianyun.com/service/roomservice/pc',
    URL_SERVICE_PC_USERSYSTEM: 'https://user0-web.zdianyun.com/service/usersystem/pc',

}