
export default {
    //乘法
    mul(arg1, arg2) {
        var m = 0;
        var s1 = arg1.toString();
        var s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length;
        } catch (e) {
        }
        try {
            m += s2.split(".")[1].length;
        } catch (e) { }

        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);

        //     arg1 = Number(arg1);
        // arg2 = Number(arg2);
        // if ((!arg1 && arg1!==0) || (!arg2 && arg2!==0)) {
        //     return null;
        // }
        // arg1 = toNonExponential(arg1);
        // arg2 = toNonExponential(arg2);
        // var n1, n2;
        // var r1, r2; // 小数位数
        // try {
        //     r1 = arg1.toString().split(".")[1].length;
        // } catch (e) {
        //     r1 = 0;
        // }
        // try {
        //     r2 = arg2.toString().split(".")[1].length;
        // } catch (e) {
        //     r2 = 0;
        // }
        // n1 = Number(arg1.toString().replace(".", ""));
        // n2 = Number(arg2.toString().replace(".", ""));
        // return n1 * n2 / Math.pow(10, r1 + r2);
    },
    //除法
    div(arg1, arg2) {
        arg1 = Number(arg1);
        arg2 = Number(arg2);
        if (!arg2) {
            return null;
        }
        if (!arg1 && arg1 !== 0) {
            return null;
        } else if (arg1 === 0) {
            return 0;
        }
        arg1 = this.toNonExponential(arg1);
        arg2 = this.toNonExponential(arg2);
        var n1, n2;
        var r1, r2; // 小数位数
        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }
        n1 = Number(arg1.toString().replace(".", ""));
        n2 = Number(arg2.toString().replace(".", ""));
        return this.mul((n1 / n2), Math.pow(10, r2 - r1));
    },
    //加法
    add(arg1, arg2) {
        var r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m + arg2 * m) / m).toFixed(n);

        //     arg1 = Number(arg1) || 0;
        // arg2 = Number(arg2) || 0;
        // arg1 = toNonExponential(arg1);
        // arg2 = toNonExponential(arg2);
        // var r1, r2, m;
        // try {
        //     r1 = arg1.toString().split(".")[1].length;
        // } catch (e) {
        //     r1 = 0;
        // }
        // try {
        //     r2 = arg2.toString().split(".")[1].length;
        // } catch (e) {
        //     r2 = 0;
        // }
        // m = Math.pow(10, Math.max(r1, r2));
        // return (floatMultiply(arg1, m) + floatMultiply(arg2, m)) / m;
    },
    //减法
    sub(arg1, arg2) {
        var re1, re2, m, n;
        try {
            re1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            re1 = 0;
        }
        try {
            re2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            re2 = 0;
        }
        m = Math.pow(10, Math.max(re1, re2));
        n = (re1 >= re2) ? re1 : re2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);

        //     arg1 = Number(arg1) || 0;
        // arg2 = Number(arg2) || 0;
        // arg1 = toNonExponential(arg1);
        // arg2 = toNonExponential(arg2);
        // var r1, r2, m, n;
        // try {
        //     r1 = arg1.toString().split(".")[1].length;
        // } catch (e) {
        //     r1 = 0;
        // }
        // try {
        //     r2 = arg2.toString().split(".")[1].length;
        // } catch (e) {
        //     r2 = 0;
        // }
        // m = Math.pow(10, Math.max(r1, r2));
        // // 动态控制精度长度
        // n = (r1 >= r2) ? r1 : r2;
        // return ((floatMultiply(arg1, m) - floatMultiply(arg2, m)) / m).toFixed(n);
    },
    /**将科学计数法的数字转为字符串 */
    toNonExponential(num) {
        if (num == null) {
            return num;
        }
        if (typeof num == "number") {
            var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
            return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
        } else {
            return num;
        }
    },
    /** 取余 -1.02 取整为 -1; 1.02取整为1 */
    sur(arg1, arg2) {
        arg1 = Number(arg1);
        arg2 = Number(arg2);
        if (!arg2) {
            return null;
        }
        if (!arg1 && arg1 !== 0) {
            return null;
        } else if (arg1 === 0) {
            return 0;
        }
        let intNum = arg1 / arg2;
        intNum = intNum < 0 ? Math.ceil(arg1 / arg2) : Math.floor(arg1 / arg2);  // -1.02 取整为 -1; 1.02取整为1
        let intVal = this.mul(intNum, arg2);
        return this.sub(arg1, intVal); 12.4
    }
}