import i18n from '@/app/i18n/global.js'
import { DEVICETYPE } from '@/app/common/resources/resources.js'
import moment from 'moment';
//全局过滤器

const toNormalFixed = (val) => {
    try {
        if (val) {
            return (Number(val)).toFixed(2);
        } else if (val == 0) {
            return '0.00';
        } else {
            return '--';
        }
    } catch (e) {
        return '--'
    }
}

const timeFormat = (val, format) => {
    try {
        if (val) {
            return format ? moment(val).format(format) : moment(val).format("YYYY-MM-DD HH:mm:ss");
        } else {
            return '--';
        }
    } catch (e) {
        return '--'
    }
}

const translate = (val) => {
    try {
        return i18n.t(val)
    } catch (e) {
        return i18n.t('lang.unknown')
    }
}

//订单状态
const orderStatus = (val) => {
    try {
        let ret = i18n.t("lang.orderclose");
        switch (val) {
            case -1:
                ret = i18n.t("lang.unknown");
                break;
            case 0:
                ret = i18n.t("lang.waitpay");
                break;
            case 1:
                ret = i18n.t("lang.ispay");
                break;
            case 2:
                ret = i18n.t("lang.paysuccess");
                break;
            case 3:
                ret = i18n.t("lang.payfail");
                break;
            case 6:
                ret = i18n.t("lang.paycancel");
                break;
            case 7:
                ret = i18n.t("lang.withoutpermission");
                break;
            case 8:
                ret = i18n.t("lang.payovertime");
                break;
            case 9:
                ret = i18n.t("lang.paywrong");
                break;
            case 16:
                ret = i18n.t("lang.cancelinprograss");
                break;
            case 18:
                ret = i18n.t("lang.timeoutinprograss");
                break;
            case 41:
                ret = i18n.t("lang.autopaycancel");
                break;
            case 42:
                ret = i18n.t("lang.autotimeouthandling");
                break;
            case 99:
                ret = i18n.t("lang.payabnormal");
                break;
            default:
                ret = i18n.t("lang.orderclose");
                break;
        }
        return ret;
    } catch (e) {
        return i18n.t('lang.unknown')
    }
}


//支付方式
const payStatus = (val) => {
        try {
            let ret = i18n.t("lang.unknown");
            switch (val) {
                case 1:
                    ret = i18n.t("lang.alipay");
                    break;
                case 2:
                    ret = i18n.t("lang.bankcard");
                    break;
                case 3:
                    ret = i18n.t("lang.wechat");
                    break;
                case 4:
                    ret = i18n.t("InterSwitch");
                    break;
                case 10:
                    ret = i18n.t("lang.alipaysmall");
                    break;
                case 128:
                    ret = i18n.t("lang.vrpay");
                    break;
                case 11:
                    ret = i18n.t("lang.wechatsmall");
                    break;
                default:
                    ret = i18n.t("lang.unknown");
                    break;
            }
            return ret;
        } catch (e) {
            return i18n.t('lang.unknown')
        }
    }
    //权限过滤
const accountName = (val) => {
    try {
        let ret = i18n.t("lang.unknown");
        switch (val) {
            case 15:
                ret = i18n.t("lang.administrator");
                break;
            case 20:
                ret = i18n.t("lang.dealers");
                break;
            case 21:
                ret = i18n.t("lang.landlord");
                break;
            case 22:
                ret = i18n.t("lang.tenant");
                break;
            default:
                ret = i18n.t("lang.unknown");
                break;
        }
        return ret;
    } catch (e) {
        return i18n.t('lang.unknown')
    }
}

const deviceType = (val) => {
    try {
        let res = i18n.t('lang.unknown');
        switch (val) {
            case DEVICETYPE.ELE:
                res = i18n.t('lang.emeter');
                break;
            case DEVICETYPE.WATER:
                res = i18n.t('lang.coldwmeter');
                break;
            case DEVICETYPE.HOT_WATER:
                res = i18n.t('lang.hotwmeter');
                break;
        }
        return res
    } catch (e) {
        return i18n.t('lang.unknown')
    }
}

const toDeviceType = (val) => {
    try {
        let res = 'lang.unknown';
        switch (val) {
            case DEVICETYPE.ELE:
                res = 'lang.emeter';
                break;
            case DEVICETYPE.WATER:
                res = 'lang.coldwmeter';
                break;
            case DEVICETYPE.HOT_WATER:
                res = 'lang.hotwmeter';
                break;
        }
        return res
    } catch (e) {
        return 'lang.unknown'
    }
}




export {
    toNormalFixed,
    translate,
    toDeviceType,
    timeFormat,
    orderStatus,
    payStatus,
    accountName,
    deviceType
}