export default {
    namespaced: true,
    state: {
        distributorName: "",//经销商名称
        distributorAccount: "",//经销商账号
        distributorSignArea: "",//经销商签约区域
        wlToken: "",//我联token
    },
    getters: {
        distributorName: state => { return state.distributorName; },
        distributorAccount: state => { return state.distributorAccount; },
        distributorSignArea: state => { return state.distributorSignArea; },
        wlToken: state => { return state.wlToken; },
    },
    mutations: {
        distributorName(state, val) { state.distributorName = val; },
        distributorAccount(state, val) { state.distributorAccount = val; },
        distributorSignArea(state, val) { state.distributorSignArea = val; },
        wlToken(state, val) { state.wlToken = val; },
    }
}