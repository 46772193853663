<template>
  <div class="block">
    <el-cascader
      ref="cascaderAddr"
      :style="{ width: width }"
      :placeholder="$t('lang.searchareatip')"
      :options="options"
      @change="areaChange"
      v-model="areaCodeArr"
      separator=" "
      filterable
    ></el-cascader>
  </div>
</template>

<script>
import { areasList } from "./area";
export default {
  props: {
    width: {
      type: String,
      default: "",
    },
    areaCode: {
      type: String | Number,
      default: "",
    },
  },
  data() {
    return {
      options: areasList,
      areaCodeArr: [],
    };
  },
  mounted() {
    console.log(this.areaCode);
    let val = String(this.areaCode);
    if (val && val.length == 6) {
      console.log(val);
      let list = [val.slice(0, 2), val.slice(0, 4), val];
      this.areaCodeArr = list;
    }
  },
  computed: {},
  watch: {
    areaCode(value) {
      let val = String(value);
      if (val && val.length == 6) {
        let list = [val.slice(0, 2), val.slice(0, 4), val];
        this.areaCodeArr = list;
      }else{
        this.areaCodeArr = [];
      }
    },
  },
  methods: {
    areaChange(e) {
      this.$emit("update:areaCode", e[2]);
      console.log(this.getAreaValue(e));
      //   console.log(this.$refs['cascaderAddr'].inputValue);
      console.log(e);
      let areaObj = {
        address: this.getAreaValue(e),
        code: e[2],
        codeList: e,
      };
      this.$emit("areaChange", areaObj);
    },

    getAreaValue(e) {
      let areaValue = null;
      areasList.map((item, index) => {
        if (item.value == e[0]) {
          areaValue = item.label + " ";
          areasList[index].children.map((i, j) => {
            if (i.value == e[1]) {
              areaValue += i.label + " ";
              areasList[index].children[j].children.map((m, n) => {
                if (m.value == e[2]) {
                  areaValue += m.label;
                }
              });
            }
          });
        }
      });
      return areaValue;
    },
  },
};
</script>